import React, { useState, forwardRef, useEffect } from "react";

import { Input } from "./styles";

type IProps = {
  getValue?: (event: any) => void;
  onEnterPress?: (amount: any) => void;
  defaultValue?: number | null;
  id?: string;
  readOnly?: boolean;
  disabled?: boolean;
  currency?: "BRL" | "PYG" | "USD";
};

const MonetaryInput = forwardRef<null, IProps>(
  (
    {
      getValue,
      onEnterPress,
      defaultValue,
      id,
      readOnly,
      disabled,
      currency = "BRL",
    },
    ref
  ) => {
    const [amount, setAmount] = useState<number>(defaultValue || 0);

    useEffect(() => {
      setAmount(+(defaultValue || 0));
    }, [defaultValue]);

    const handleChange = (event, value) => {
      if (typeof value !== "number" && typeof value !== "string") {
        return;
      }
      event.preventDefault();
      setAmount(+value);
      if (getValue) getValue(+value);
    };

    const handleKeyPress = (event) => {
      if (onEnterPress && event.key === "Enter") {
        onEnterPress(amount);
      }
    };
    const onBlur = () => {
      if (onEnterPress) onEnterPress(amount);
    };

    const currencyConfig = {
      locale:
        currency === "PYG" ? "es-PY" : currency === "USD" ? "en-US" : "pt-BR",
      formats: {
        number: {
          BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
          PYG: {
            style: "currency",
            currency: "PYG",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
          USD: {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    };

    return (
      <Input
        disabled={disabled}
        readOnly={readOnly}
        ref={ref}
        id={id}
        value={amount}
        currency={currency}
        className="ant-input"
        config={currencyConfig}
        onChange={handleChange}
        max={currency === "PYG" ? 99999999 : 9999999}
        onKeyPress={handleKeyPress}
        onBlur={onBlur}
        onClick={({ target }) => {
          target.selectionStart = 10000;
        }}
      />
    );
  }
);

export default MonetaryInput;
