import React, { useState, useEffect } from "react";

import { Switch, Route, RouteProps } from "react-router-dom";

import { isAuthenticated, verifyPermission } from "../services/auth";

import Login from "../pages/Login";
import Home from "../pages/Home";
import BenchMark from "../pages/BenchMark";
import Handler from "../pages/Handler";
import ListOrder from "../pages/Order/OrderPage";
import Analytics from "../pages/Analytics";
import Administration from "../pages/Administration";
import Products from "../pages/Products";
import Stock from "../pages/Stock";
import ProductFranchisee from "../pages/ProductFranchisee";
import Shop from "../pages/Shop/pages/OrderList";
import StandardFreight from "../pages/StandardFreight";
import Royalty from "../pages/Royalty";
import Category from "../pages/Category";
import PurchaseReport from "../pages/Shop/pages/PurchaseReport";

import Layout from "../containers/Layout/index";
import CashManagement from "../pages/CashManagement";
import Waste from "../pages/Waste";
import Movements from "../pages/Movements";
import PontosApp from "../pages/PontosApp";
import Nfe from "../pages/Nfe";
import MarketingDigitalScreen from "../pages/MarketingDigitalScreen";
import Cupom from "../pages/Cupom";
import DeliveryRoutes from "../pages/DeliveryRoutes";
import Driver from "../pages/Driver";
import CompanyInvoice from "../pages/CompanyInvoice";
import InfoScreen from "../pages/Driver/InfoScreen";
import OrdersSendBlingPY from "../pages/Order/OrderSendBlingPY";
import ListOrderPY from "../pages/Order/OrderPagePY";
import OrdersChangeStatus from "../pages/Order/OrderPageChangeStatus";

interface PrivateRouteProps extends RouteProps {
  component: any;
  permission?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, permission, ...rest } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const sendToLogin = () => {
      const link = document.createElement("a");
      link.href = process.env.REACT_APP_WEB_AUTH_URL as string;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const fetchPermissionWithRole = async () => {
      const response = await verifyPermission(permission);
      if (response) {
        setLoading(false);
      } else {
        sendToLogin();
      }
    };
    const fetchPermissionWithOutRole = async () => {
      const response = await isAuthenticated();
      if (response) {
        setLoading(false);
      } else {
        sendToLogin();
      }
    };
    if (permission) {
      fetchPermissionWithRole();
    } else {
      fetchPermissionWithOutRole();
    }
  }, [permission, Component]);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        loading ? (
          <></>
        ) : (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        )
      }
    />
  );
};

const Routes = () => (
  <Switch>
    <Route exact path="/login/:token" component={Login} />
    <PrivateRoute exact path="/" component={Administration} />
    <PrivateRoute
      exact
      path="/benchmark"
      component={BenchMark}
      permission={"benchmarking.route"}
    />
    <PrivateRoute exact path="/handler" component={Handler} />

    <PrivateRoute
      exact
      path="/orders"
      component={ListOrder}
      permission={"orders.route"}
    />

    <PrivateRoute
      exact
      path="/orders_py"
      component={ListOrderPY}
      permission={"orders.route"}
    />

    <PrivateRoute
      exact
      path="/send_bling_py"
      component={OrdersSendBlingPY}
      permission={"orders.route"}
    />
    <PrivateRoute
      exact
      path="/orders_change_status"
      component={OrdersChangeStatus}
      permission={"orders.route"}
    />
    <PrivateRoute exact path="/nfe" component={Nfe} permission={"nfe.route"} />
    <PrivateRoute
      exact
      path="/shop"
      component={Shop}
      permission={"shop.route"}
    />
    <PrivateRoute
      exact
      path="/stock"
      component={Stock}
      permission={"stock.route"}
    />
    <PrivateRoute
      exact
      path="/analytics"
      component={Analytics}
      permission={"analytics.route"}
    />
    {/* <PrivateRoute exact path="/administration" component={Administration} /> */}

    <PrivateRoute
      exact
      path="/product"
      component={Products}
      permission={"product.route"}
    />

    <PrivateRoute
      exact
      path="/product_franchisee"
      component={ProductFranchisee}
      permission={"product_franchisee.route"}
    />

    <PrivateRoute
      exact
      path="/cash_management"
      component={CashManagement}
      permission={"cash_history.route"}
    />
    <PrivateRoute
      exact
      path="/standard_freight"
      component={StandardFreight}
      permission={"standard_freight.route"}
    />

    <PrivateRoute
      exact
      path="/royalty"
      component={Royalty}
      permission={"royalty.route"}
    />

    <PrivateRoute
      exact
      path="/category"
      component={Category}
      permission={"category.route"}
    />

    <PrivateRoute
      exact
      path="/shop_report/:store_id"
      component={PurchaseReport}
    />

    <PrivateRoute exact path="/pontos_app" component={PontosApp} />
    <PrivateRoute exact path="/waste" component={Waste} />
    <PrivateRoute exact path="/movements" component={Movements} />
    <PrivateRoute
      exact
      path="/digital-marketing"
      component={MarketingDigitalScreen}
    />
    <PrivateRoute exact path="/routes" component={DeliveryRoutes} />
    <PrivateRoute exact path="/driver" component={Driver} />
    <PrivateRoute exact path="/info-screen" component={InfoScreen} />
    <PrivateRoute exact path="/company_invoice" component={CompanyInvoice} />
    <PrivateRoute exact path="/club_cupom" component={Cupom} />

    <PrivateRoute exact path="*" component={Home} />
  </Switch>
);

export default Routes;
