import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ItemsNfe } from "../../../models/NfeXML";

import originICMS from "../models/originICMS.json";
import ICMSBaseCalc from "../models/ICMSBaseCalc.json";
import codigoSeloIpi from "../models/codigoSeloIpi.json";
import situationTrib from "../models/situationTrib.json";
import modalityBcICMS from "../models/modalityBcICMS.json";
import ipiSituacaoTrib from "../models/ipiSituacaoTrib.json";
import pisCofinsTribSituation from "../models/pisCofinsTribSituation.json";

import Centralizer from "../../../containers/Centralizer";

import InputMask from "../../../components/InputMask";
import MonetaryInput from "../../../components/MonetaryInput2";
import InputCurrency from "../InputCurrency";

import { Spin, notification, Tooltip } from "antd";

import {
  Button,
  Col,
  Drawer,
  Form,
  IconInfo,
  Input,
  Row,
  Select,
  Tabs,
  TextAreaInput,
  Title,
} from "./styles";
import { set } from "lodash";

const { TabPane } = Tabs;

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectItem: any;
  setSelectItem: Dispatch<SetStateAction<any>>;
  items: ItemsNfe[];
  setItems: Dispatch<SetStateAction<any[]>>;
  setLoadingItems: Dispatch<SetStateAction<boolean>>;
}

const NFEDrawerItem: React.FC<IProps> = ({
  visible,
  setVisible,
  selectItem,
  items,
  setItems,
  setLoadingItems,
}) => {
  const [form] = Form.useForm();
  const [formDados] = Form.useForm();
  const [formICMSTax] = Form.useForm();
  const [formICMSGeral] = Form.useForm();
  const [formICMSSituationTrib] = Form.useForm();
  const [formPIS] = Form.useForm();
  const [formIPI] = Form.useForm();

  const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
  const [loadingReset, setLoadingReset] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [inputItems, setInputItems] = useState<{
    valor_frete: number | null;
    valor_desconto: number | null;
    valor_seguro: number | null;
    icms_valor: number | null;
    icms_base_calculo: number | null;
    icms_valor_st: number | null;
    icms_base_calculo_st: number | null;
    ipi_base_calculo: number | null;
    ipi_valor: number | null;
    valor_outras_despesas: number | null;
    icms_valor_diferido: number | null;
    icms_valor_operacao: number | null;
  }>({
    valor_frete: null,
    valor_desconto: null,
    valor_seguro: null,
    icms_valor: null,
    icms_base_calculo: null,
    icms_valor_st: null,
    icms_base_calculo_st: null,
    ipi_valor: null,
    ipi_base_calculo: null,
    valor_outras_despesas: null,
    icms_valor_diferido: null,
    icms_valor_operacao: null,
  });

  const [selects, setSelects] = useState<{
    cofins_situacao_tributaria: string;
    icms_modalidade_base_calculo: string;
    icms_modalidade_base_calculo_st: string;
    icms_origem: string;
    icms_situacao_tributaria: string;
    pis_situacao_tributaria: string;
    icms_motivo_reducao: string;
    ipi_situacao_tributaria: string;
    ipi_codigo_selo_controle: string;
  }>({
    cofins_situacao_tributaria: "",
    icms_modalidade_base_calculo: "",
    icms_modalidade_base_calculo_st: "",
    icms_origem: "",
    icms_situacao_tributaria: "",
    pis_situacao_tributaria: "",
    icms_motivo_reducao: "",
    ipi_situacao_tributaria: "",
    ipi_codigo_selo_controle: "",
  });

  useEffect(() => {
    const resetForms = () => {
      setLoadingReset(true);
      form.resetFields();
      formDados.resetFields();
      formICMSTax.resetFields();
      formICMSGeral.resetFields();
      formICMSSituationTrib.resetFields();
      formIPI.resetFields();
      formPIS.resetFields();
      setSelects({
        cofins_situacao_tributaria: "",
        icms_modalidade_base_calculo: "",
        icms_modalidade_base_calculo_st: "",
        icms_origem: "",
        icms_situacao_tributaria: "",
        pis_situacao_tributaria: "",
        icms_motivo_reducao: "",
        ipi_situacao_tributaria: "",
        ipi_codigo_selo_controle: "",
      });
      setTimeout(() => {
        setLoadingReset(false);
      }, 100);
    };

    const setFields = () => {
      setLoadingReset(true);
      form.setFieldsValue({
        codigo_produto: selectItem?.codigo_produto,
        descricao: selectItem?.descricao,
      });
      setSelects(() => ({
        icms_situacao_tributaria:
          (selectItem?.icms_situacao_tributaria === "0"
            ? "00"
            : selectItem?.icms_situacao_tributaria === "2"
            ? "02"
            : selectItem?.icms_situacao_tributaria) || "",
        icms_origem: selectItem?.icms_origem || "",
        icms_modalidade_base_calculo:
          (+(selectItem?.icms_modalidade_base_calculo || ""))?.toString() || "",
        pis_situacao_tributaria: selectItem?.pis_situacao_tributaria || "",
        cofins_situacao_tributaria:
          selectItem?.cofins_situacao_tributaria || "",
        icms_modalidade_base_calculo_st:
          (+(selectItem?.icms_modalidade_base_calculo_st || ""))?.toString() ||
          "",
        icms_motivo_reducao: selectItem?.icms_motivo_reducao || "",
        ipi_situacao_tributaria: selectItem?.ipi_situacao_tributaria || "",
        ipi_codigo_selo_controle: selectItem?.ipi_codigo_selo_controle || "",
      }));

      setInputItems({
        valor_frete: +selectItem?.valor_frete || 0,
        valor_desconto: +selectItem?.valor_desconto || 0,
        valor_seguro: +selectItem?.valor_seguro || 0,
        icms_base_calculo: +selectItem?.icms_base_calculo || 0,
        icms_valor: +selectItem?.icms_valor || 0,
        icms_base_calculo_st: +selectItem?.icms_base_calculo_st || 0,
        icms_valor_st: +selectItem?.icms_valor_st || 0,
        ipi_base_calculo: +selectItem?.ipi_base_calculo || 0,
        ipi_valor: +selectItem?.ipi_valor || 0,
        valor_outras_despesas: +selectItem?.valor_outras_despesas || 0,
        icms_valor_diferido: +selectItem?.icms_valor_diferido || 0,
        icms_valor_operacao: +selectItem?.icms_valor_operacao || 0,
      });

      setQuantity(selectItem?.quantidade_comercial);

      formDados.setFieldsValue({
        natureza_operacao: selectItem?.natureza_operacao,
        quantidade_comercial: selectItem?.quantidade_comercial,
        unidade_comercial: selectItem?.unidade_comercial,
        valor_unitario_comercial: +selectItem?.valor_unitario_comercial || 0,

        cfop: selectItem?.cfop,
        codigo_ncm: selectItem?.codigo_ncm,
        cest: selectItem?.cest,
        codigo_beneficio_fiscal: selectItem?.codigo_beneficio_fiscal,
        codigo_barras_comercial: selectItem?.codigo_barras_comercial,
        codigo_barras_tributavel: selectItem?.codigo_barras_tributavel,
        codigo_barras_proprio_comercial:
          selectItem?.codigo_barras_proprio_comercial,
        codigo_barras_proprio_tributavel:
          selectItem?.codigo_barras_proprio_tributavel,
        peso_liquido: selectItem?.peso_liquido,
        informacoes_adicionais: selectItem?.informacoes_adicionais,
      });
      setTotalItem(
        +(
          +selectItem?.valor_unitario_comercial *
          +selectItem?.quantidade_comercial
        )?.toFixed(2)
      );

      formICMSTax.setFieldsValue({
        valor_total_tributos: +selectItem?.valor_total_tributos || 0,
      });

      formICMSGeral.setFieldsValue({
        icms_aliquota: selectItem?.icms_aliquota || 0,
        icms_aliquota_credito_simples:
          selectItem?.icms_aliquota_credito_simples || 0,
        icms_aliquota_final: selectItem?.icms_aliquota_final || 0,
        icms_aliquota_retencao: selectItem?.icms_aliquota_retencao || 0,
        icms_aliquota_retido: selectItem?.icms_aliquota_retido || 0,
        icms_aliquota_st: selectItem?.icms_aliquota_st || 0,
        icms_base_calculo_mono: selectItem?.icms_base_calculo_mono || 0,
        icms_base_calculo_mono_retencao:
          selectItem?.icms_base_calculo_mono_retencao || 0,
        icms_base_calculo_mono_retido:
          selectItem?.icms_base_calculo_mono_retido || 0,
        icms_base_calculo_retido_st:
          selectItem?.icms_base_calculo_retido_st || 0,
        icms_base_calculo_st: selectItem?.icms_base_calculo_st || 0,
        icms_margem_valor_adicionado_st:
          selectItem?.icms_margem_valor_adicionado_st || 0,
        icms_percentual_diferimento:
          selectItem?.icms_percentual_diferimento || 0,
        icms_percentual_reducao: selectItem?.icms_percentual_reducao || 0,
        icms_reducao_base_calculo: selectItem?.icms_reducao_base_calculo || 0,
        icms_reducao_base_calculo_st:
          selectItem?.icms_reducao_base_calculo_st || 0,
        icms_valor_credito_simples: selectItem?.icms_valor_credito_simples || 0,
        icms_valor_desonerado: selectItem?.icms_valor_desonerado || 0,
        icms_valor_diferido: selectItem?.icms_valor_diferido || 0,
        icms_valor_mono: selectItem?.icms_valor_mono || 0,
        icms_valor_mono_diferido: selectItem?.icms_valor_mono_diferido || 0,
        icms_valor_mono_operacao: selectItem?.icms_valor_mono_operacao || 0,
        icms_valor_mono_retencao: selectItem?.icms_valor_mono_retencao || 0,
        icms_valor_mono_retido: selectItem?.icms_valor_mono_retido || 0,
        icms_valor_operacao: selectItem?.icms_valor_operacao || 0,
        icms_valor_retido_st: selectItem?.icms_valor_retido_st || 0,
        icms_valor_substituto: selectItem?.icms_valor_substituto || 0,
      });

      formPIS.setFieldsValue({
        pis_valor: selectItem?.pis_valor || 0,
        pis_aliquota_porcentual: selectItem?.pis_aliquota_porcentual || 0,
        pis_base_calculo: selectItem?.pis_base_calculo || 0,
        cofins_valor: selectItem?.cofins_valor || 0,
        cofins_aliquota_porcentual: selectItem?.cofins_aliquota_porcentual || 0,
        cofins_base_calculo: selectItem?.cofins_base_calculo || 0,
      });

      formICMSSituationTrib.setFieldsValue({
        icms_margem_valor_adicionado_st:
          selectItem?.icms_margem_valor_adicionado_st || 0,
        icms_base_calculo_st: selectItem?.icms_base_calculo_st || 0,
        icms_aliquota_st: selectItem?.icms_aliquota_st || 0,
        icms_valor_st: selectItem?.icms_valor_st || 0,
        icms_valor_icms_st_desonerado:
          selectItem?.icms_valor_icms_st_desonerado || 0,
        pis_base_calculo_st: selectItem?.pis_base_calculo_st || 0,
        pis_aliquota_porcentual_st: selectItem?.pis_aliquota_porcentual_st || 0,
        pis_valor_st: selectItem?.pis_valor_st || 0,
        cofins_base_calculo_st: selectItem?.cofins_base_calculo_st || 0,
        cofins_aliquota_porcentual_st:
          selectItem?.cofins_aliquota_porcentual_st || 0,
        cofins_valor_st: selectItem?.cofins_valor_st || 0,
      });

      formIPI.setFieldsValue({
        ipi_aliquota: selectItem?.ipi_aliquota || 0,
        ipi_valor: selectItem?.ipi_valor || 0,
        ipi_quantidade_total: selectItem?.ipi_quantidade_total || 0,
        ipi_valor_por_unidade_tributavel:
          selectItem?.ipi_valor_por_unidade_tributavel || 0,
        ipi_cnpj_produtor: selectItem?.ipi_cnpj_produtor,
        ipi_quantidade_selo_controle:
          selectItem?.ipi_quantidade_selo_controle || 0,
        ipi_codigo_enquadramento_legal:
          selectItem?.ipi_codigo_enquadramento_legal || 0,
      });

      setTimeout(() => {
        setLoadingReset(false);
      }, 100);
    };

    if (visible) {
      setFields();
    } else {
      resetForms();
    }
  }, [visible]);

  const changeQuantity = async (event) => {
    setLoadingTotal(true);
    const calcTotal =
      +selectItem?.valor_unitario_comercial * +event.target.value;

    const freteCalc = selectItem.valor_frete_quantity * +event.target.value;
    const descontoCalc =
      selectItem.valor_desconto_quantity * +event.target.value;
    const seguroCalc = selectItem.valor_seguro_quantity * +event.target.value;
    const bcIcmsCalc =
      selectItem.icms_base_calculo_quantity * +event.target.value;
    const icmsValorCalc =
      selects.icms_situacao_tributaria !== "51" &&
      selects.icms_situacao_tributaria !== "900"
        ? +bcIcmsCalc *
          (+(formICMSGeral.getFieldValue("icms_aliquota") || 0) / 100)
        : +bcIcmsCalc *
          +(
            +(formICMSGeral.getFieldValue("icms_aliquota") || 0) / 100 -
            +(
              ((+(formICMSGeral.getFieldValue("icms_aliquota") || 0) / 100) *
                +(
                  formICMSGeral.getFieldValue("icms_percentual_diferimento") ||
                  0
                )) /
              100
            )
          );
    const bcIcmsSTCalc =
      selectItem.icms_base_calculo_st_quantity * +event.target.value;
    const icmsValorSTCalc =
      +bcIcmsSTCalc *
        (+(formICMSGeral.getFieldValue("icms_aliquota_st") || 0) / 100) -
      (icmsValorCalc || 0);
    const ipiCalc = selectItem.ipi_base_calculo_quantity * +event.target.value;
    const ipiValorCalc = +ipiCalc * (+(selectItem.ipi_aliquota || 0) / 100);
    const outrasDespCalc =
      selectItem.valor_outras_despesas_quantity * +event.target.value;
    let valorDif = 0;
    let valorOpe = 0;

    valorDif =
      bcIcmsCalc *
      +(
        ((+(formICMSGeral.getFieldValue("icms_aliquota") || 0) / 100) *
          +(formICMSGeral.getFieldValue("icms_percentual_diferimento") || 0)) /
        100
      );

    valorOpe = icmsValorCalc + valorDif;

    setInputItems({
      valor_frete: +(freteCalc || 0)?.toFixed(2),
      valor_desconto: +(descontoCalc || 0)?.toFixed(2),
      valor_seguro: +(seguroCalc || 0)?.toFixed(2),
      icms_valor: +(icmsValorCalc || 0)?.toFixed(2),
      icms_base_calculo: +(bcIcmsCalc || 0)?.toFixed(2),
      icms_valor_st:
        icmsValorSTCalc > 0 ? +(icmsValorSTCalc || 0)?.toFixed(2) : 0,
      icms_base_calculo_st:
        bcIcmsSTCalc > 0 ? +(bcIcmsSTCalc || 0)?.toFixed(2) : 0,
      ipi_base_calculo: +(ipiCalc || 0)?.toFixed(2),
      ipi_valor: +(ipiValorCalc || 0)?.toFixed(2),
      valor_outras_despesas: +(outrasDespCalc || 0)?.toFixed(2),
      icms_valor_diferido: +(valorDif || 0)?.toFixed(2),
      icms_valor_operacao: +(valorOpe || 0)?.toFixed(2),
    });

    setQuantity(+event.target.value);
    setTotalItem(calcTotal);
    setTimeout(() => {
      setLoadingTotal(false);
    }, 100);
  };

  const changeValue = async (key: string, value: number) => {
    setInputItems((oldValues) => ({ ...oldValues, [key]: value }));
  };

  const icmsCalc = async (key: string, value: number) => {
    if (key === "icms_aliquota") {
      const valorICMS = +(inputItems?.icms_base_calculo || 0) * (value / 100);
      setInputItems((oldValues) => ({
        ...oldValues,
        icms_valor: +valorICMS?.toFixed(2),
      }));
    } else {
      const icmsAliq = formICMSGeral.getFieldValue("icms_aliquota");
      const valorICMS = +(value || 0) * (icmsAliq / 100);
      setInputItems((oldValues) => ({
        ...oldValues,
        icms_base_calculo: +value,
        icms_valor: +valorICMS?.toFixed(2),
      }));
    }
  };

  const icmsCalcST = async (key: string, value: number) => {
    if (key === "icms_aliquota_st") {
      const valorICMSST =
        +((inputItems?.icms_base_calculo_st || 0) * (value / 100)) -
        (inputItems?.icms_valor || 0);
      setInputItems((oldValues) => ({
        ...oldValues,
        icms_valor_st:
          +valorICMSST?.toFixed(2) > 0 ? +valorICMSST?.toFixed(2) : 0,
      }));
    } else {
      const icmsAliqST = formICMSGeral.getFieldValue("icms_aliquota_st");
      const valorICMSST =
        +(value || 0) * (icmsAliqST / 100) - (inputItems?.icms_valor || 0);
      setInputItems((oldValues) => ({
        ...oldValues,
        icms_base_calculo_st: +value,
        icms_valor_st:
          +valorICMSST?.toFixed(2) > 0 ? +valorICMSST?.toFixed(2) : 0,
      }));
    }
  };

  const setCalcForm = async (value) => {
    const bcIcmsCalc = +(
      (selectItem?.icms_base_calculo_quantity || 0) * quantity
    );
    const valorICMS =
      value !== "51" && value !== "900"
        ? bcIcmsCalc * (selectItem.icms_aliquota_quantity / 100)
        : +(
            +bcIcmsCalc *
            +(
              +(selectItem.icms_aliquota / 100) -
              +(
                +(selectItem.icms_aliquota / 100) *
                (+(selectItem.icms_percentual_diferimento || 0) / 100)
              )
            )
          );

    const valorICMSST =
      +((selectItem?.icms_base_calculo_st_quantity || 0) * quantity) *
        (selectItem.icms_aliquota_st_quantity / 100) -
      (valorICMS || 0);

    const valorDif =
      bcIcmsCalc *
      +(
        +(selectItem.icms_aliquota_quantity / 100) *
        (+(selectItem.icms_percentual_diferimento || 0) / 100)
      );

    const valorOpe = valorICMS + valorDif;

    await formICMSGeral.setFieldsValue({
      icms_aliquota: +selectItem?.icms_aliquota_quantity || 0,
      icms_aliquota_st: +selectItem?.icms_aliquota_st_quantity || 0,
    });

    setInputItems((oldValues) => ({
      ...oldValues,
      icms_base_calculo:
        +(selectItem?.icms_base_calculo_quantity || 0) * quantity,
      icms_valor: +valorICMS?.toFixed(2),
      icms_base_calculo_st:
        +(selectItem?.icms_base_calculo_st_quantity || 0) * quantity,
      icms_valor_st:
        +valorICMSST?.toFixed(2) > 0 ? +valorICMSST?.toFixed(2) : 0,
      icms_valor_diferido: +(valorDif || 0)?.toFixed(2),
      icms_valor_operacao: +(valorOpe || 0)?.toFixed(2),
    }));
  };

  const ipiCalc = async (key: string, value: number) => {
    if (key === "ipi_aliquota") {
      const valorIPI = +(inputItems?.ipi_base_calculo || 0) * (value / 100);
      setInputItems((oldValues) => ({
        ...oldValues,
        ipi_valor: +valorIPI?.toFixed(2),
      }));
    } else {
      const ipiAliq = formIPI.getFieldValue("ipi_aliquota");
      const valorIPI = +(value || 0) * (ipiAliq / 100);
      setInputItems((oldValues) => ({
        ...oldValues,
        ipi_valor: +valorIPI?.toFixed(2),
      }));
    }
  };

  const verifyForms = async () => {
    try {
      await form.validateFields();
      await formDados.validateFields();
      await formICMSSituationTrib.validateFields();
      await formICMSGeral.validateFields();
      await formICMSTax.validateFields();
      await formPIS.validateFields();

      return false;
    } catch (e) {
      return true;
    }
  };

  const onFinish = async () => {
    if (await verifyForms()) {
      return notification.warning({
        message: "Preencha todos os campos corretamente.",
        description:
          "Verifique se as informações de todas as abas foram preenchidas corretamente",
        duration: 5,
      });
    }

    setLoadingItems(true);
    try {
      let payload = await Object.assign(
        {},
        form.getFieldsValue(),
        formDados.getFieldsValue(),
        formICMSTax.getFieldsValue(),
        formICMSGeral.getFieldsValue(),
        formPIS.getFieldsValue(),
        formICMSSituationTrib.getFieldsValue(),
        formIPI.getFieldsValue()
      );

      payload = {
        ...payload,
        quantidade_comercial: quantity,
        valor_frete_quantity: +(+(inputItems?.valor_frete || 0) / quantity),
        valor_desconto_quantity: +(
          +(inputItems?.valor_desconto || 0) / quantity
        ),
        valor_seguro_quantity: +(+(inputItems?.valor_seguro || 0) / quantity),
        valor_outras_despesas_quantity: +(
          +(inputItems?.valor_outras_despesas || 0) / quantity
        ),
        icms_base_calculo_quantity: +(
          +(inputItems?.icms_base_calculo || 0) / quantity
        ),
        icms_aliquota_quantity: formICMSGeral.getFieldValue("icms_aliquota"),
        icms_base_calculo_st_quantity: +(
          +(inputItems?.icms_base_calculo_st || 0) / quantity
        ),
        icms_aliquota_st_quantity:
          formICMSGeral.getFieldValue("icms_aliquota_st"),
        ipi_base_calculo_quantity: +(
          +(inputItems?.ipi_base_calculo || 0) / quantity
        ),
        quantidade_max_comercial: selectItem?.quantidade_max_comercial,
        valor_frete: inputItems.valor_frete,
        valor_desconto: inputItems.valor_desconto,
        valor_seguro: inputItems.valor_seguro,
        valor_outras_despesas: inputItems.valor_outras_despesas,
        icms_base_calculo:
          selects.icms_situacao_tributaria === "00" ||
          selects.icms_situacao_tributaria === "10" ||
          selects.icms_situacao_tributaria === "10_partilha" ||
          selects.icms_situacao_tributaria === "20" ||
          selects.icms_situacao_tributaria === "51" ||
          selects.icms_situacao_tributaria === "70" ||
          selects.icms_situacao_tributaria === "90" ||
          selects.icms_situacao_tributaria === "90_partilha" ||
          selects.icms_situacao_tributaria === "900"
            ? inputItems.icms_base_calculo?.toFixed(2)
            : 0,
        icms_valor:
          selects.icms_situacao_tributaria === "00" ||
          selects.icms_situacao_tributaria === "10" ||
          selects.icms_situacao_tributaria === "10_partilha" ||
          selects.icms_situacao_tributaria === "20" ||
          selects.icms_situacao_tributaria === "51" ||
          selects.icms_situacao_tributaria === "70" ||
          selects.icms_situacao_tributaria === "90" ||
          selects.icms_situacao_tributaria === "90_partilha" ||
          selects.icms_situacao_tributaria === "900"
            ? inputItems.icms_valor?.toFixed(2)
            : 0,
        icms_base_calculo_st:
          selects.icms_situacao_tributaria === "10" ||
          selects.icms_situacao_tributaria === "10_partilha" ||
          selects.icms_situacao_tributaria === "30" ||
          selects.icms_situacao_tributaria === "70" ||
          selects.icms_situacao_tributaria === "90" ||
          selects.icms_situacao_tributaria === "90_partilha" ||
          selects.icms_situacao_tributaria === "201" ||
          selects.icms_situacao_tributaria === "202" ||
          selects.icms_situacao_tributaria === "203" ||
          selects.icms_situacao_tributaria === "900"
            ? inputItems.icms_base_calculo_st?.toFixed(2)
            : 0,
        icms_valor_st:
          selects.icms_situacao_tributaria === "10" ||
          selects.icms_situacao_tributaria === "10_partilha" ||
          selects.icms_situacao_tributaria === "30" ||
          selects.icms_situacao_tributaria === "70" ||
          selects.icms_situacao_tributaria === "90" ||
          selects.icms_situacao_tributaria === "90_partilha" ||
          selects.icms_situacao_tributaria === "201" ||
          selects.icms_situacao_tributaria === "202" ||
          selects.icms_situacao_tributaria === "203" ||
          selects.icms_situacao_tributaria === "900"
            ? inputItems.icms_valor_st?.toFixed(2)
            : 0,
        ipi_base_calculo:
          selects.ipi_situacao_tributaria === "00" ||
          selects.ipi_situacao_tributaria === "49" ||
          selects.ipi_situacao_tributaria === "50" ||
          selects.ipi_situacao_tributaria === "99"
            ? inputItems.ipi_base_calculo?.toFixed(2)
            : 0,
        ipi_valor:
          selects.ipi_situacao_tributaria === "00" ||
          selects.ipi_situacao_tributaria === "49" ||
          selects.ipi_situacao_tributaria === "50" ||
          selects.ipi_situacao_tributaria === "99"
            ? inputItems?.ipi_valor?.toFixed(2)
            : 0,
        icms_valor_diferido: +(inputItems?.icms_valor_diferido || 0)?.toFixed(
          2
        ),
        icms_valor_operacao: +(inputItems?.icms_valor_operacao || 0)?.toFixed(
          2
        ),
        cfop: payload.cfop?.toString(),
        codigo_produto: payload.codigo_produto?.toString(),
        valor_bruto: totalItem?.toFixed(2),
        valor_total_tributos: +payload.valor_total_tributos?.toFixed(2),
        valor_unitario_comercial: +payload.valor_unitario_comercial?.toFixed(2),
      };

      if (
        selects.icms_situacao_tributaria !== "51" &&
        selects.icms_situacao_tributaria !== "900"
      ) {
        delete payload.icms_valor_diferido;
        delete payload.icms_valor_operacao;
      }

      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key] === undefined) {
          delete payload[key];
        }
      }

      payload = await Object.assign({}, payload, selects);

      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key] === "") {
          delete payload[key];
        }
      }
      let _itemsSubstituition = items;
      const indexItem = _itemsSubstituition.findIndex(
        (_item) => _item.descricao === payload.descricao
      );
      _itemsSubstituition[indexItem] = payload;

      setItems(_itemsSubstituition);
      setVisible(false);
    } catch (e) {
      console.error(e);
    } finally {
      setSelects({
        cofins_situacao_tributaria: "",
        icms_modalidade_base_calculo: "",
        icms_modalidade_base_calculo_st: "",
        icms_origem: "",
        icms_situacao_tributaria: "",
        pis_situacao_tributaria: "",
        icms_motivo_reducao: "",
        ipi_situacao_tributaria: "",
        ipi_codigo_selo_controle: "",
      });
      setTimeout(() => {
        setLoadingItems(false);
      }, 100);
    }
  };

  const resetFormErrors = (formName, value) => {
    formName.setFields([
      {
        name: value,
        errors: [],
      },
    ]);
  };

  return (
    <Drawer
      title={<Title>Item da nota fiscal</Title>}
      visible={visible}
      onClose={() => setVisible(false)}
      maskClosable
      closable
      width={window.outerWidth < 768 ? "100%" : "70%"}
      footer={
        <>
          <Button onClick={() => onFinish()}>Salvar</Button>
        </>
      }
    >
      <>
        {loadingReset ? (
          <Centralizer>
            <Spin />
          </Centralizer>
        ) : (
          <>
            <Form layout="vertical" form={form} validateTrigger="onSubmit">
              <Row gutter={[8, 8]}>
                <Col sm={12} xs={24}>
                  <Form.Item label="Descrição" name="descricao">
                    <Input name="descricao" readOnly />
                  </Form.Item>
                </Col>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label="Código"
                    name="codigo_produto"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input
                      name="codigo_produto"
                      onClick={() => resetFormErrors(form, "codig_produto")}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Tabs defaultActiveKey="1">
              <TabPane tab="Dados do item" key="1" forceRender={true}>
                <Form
                  layout="vertical"
                  form={formDados}
                  validateTrigger="onSubmit"
                >
                  <Row gutter={[4, 4]}>
                    <Col sm={2} xs={24}>
                      <Form.Item
                        label="Quantidade"
                        name="quantidade_comercial"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                          {
                            validator: () => {
                              return quantity <=
                                +selectItem?.quantidade_max_comercial ||
                                quantity >= 1
                                ? Promise.resolve()
                                : Promise.reject("Qtd incorreta");
                            },
                          },
                        ]}
                      >
                        <Input
                          name="quantidade_comercial"
                          placeholder="Qtd"
                          type="number"
                          max={+selectItem?.quantidade_max_comercial}
                          min={1}
                          onChange={(value) => changeQuantity(value)}
                          onClick={() =>
                            resetFormErrors(formDados, "quantidade_comercial")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={2} xs={24}>
                      <Form.Item
                        label="Unidade"
                        name="unidade_comercial"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                          {
                            max: 6,
                            message:
                              "Unidade deve conter no máximo 6 caracteres",
                          },
                        ]}
                      >
                        <Input
                          name="unidade_comercial"
                          placeholder="Unidade"
                          onClick={() =>
                            resetFormErrors(formDados, "unidade_comercial")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Valor Unitário"
                        name="valor_unitario_comercial"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <InputCurrency
                          type="currency"
                          name="valor_unitario_comercial"
                          defaultValue={0}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor Total" name="valor_bruto">
                        <MonetaryInput
                          defaultValue={loadingTotal ? totalItem : totalItem}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor de Frete" name="valor_frete">
                        <MonetaryInput
                          defaultValue={
                            loadingTotal
                              ? inputItems?.valor_frete
                              : inputItems?.valor_frete
                          }
                          getValue={(value) =>
                            changeValue("valor_frete", +value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor Desconto" name="valor_desconto">
                        <MonetaryInput
                          defaultValue={
                            loadingTotal
                              ? inputItems?.valor_desconto
                              : inputItems?.valor_desconto
                          }
                          getValue={(value) =>
                            changeValue("valor_desconto", +value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor Seguro" name="valor_seguro">
                        <MonetaryInput
                          defaultValue={
                            loadingTotal
                              ? inputItems?.valor_seguro
                              : inputItems?.valor_seguro
                          }
                          getValue={(value) =>
                            changeValue("valor_seguro", +value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Valor Outras Despesas"
                        name="valor_outras_despesas"
                      >
                        <MonetaryInput
                          defaultValue={
                            loadingTotal
                              ? inputItems?.valor_outras_despesas
                              : inputItems?.valor_outras_despesas
                          }
                          getValue={(value) =>
                            changeValue("valor_outras_despesas", +value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Tipo de desconto">
                        <Select placeholder="Selecione Aqui" defaultValue={1}>
                          <Select.Option key={1} value={1}>
                            Condicional
                          </Select.Option>
                          <Select.Option key={2} value={2}>
                            Incondicional
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Natureza da operação"
                        name="natureza_operacao"
                      >
                        <Input
                          name="natureza_operacao"
                          placeholder="Natureza da operação"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="CFOP"
                        name="cfop"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                          { min: 4, message: "Campo deve conter 4 números" },
                          { max: 4, message: "Campo deve conter 4 números" },
                          {
                            pattern: /^\d+$/,
                            message: "O número deve ser positivo",
                          },
                        ]}
                      >
                        <Input
                          name="cfop"
                          placeholder="CFOP"
                          type="number"
                          min={0}
                          onClick={() => resetFormErrors(formDados, "cfop")}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Código NCM"
                        name="codigo_ncm"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                          { min: 8, message: "Minimo de 8 caracteres" },
                          { max: 8, message: "Máximo de 8 caracteres" },
                        ]}
                      >
                        <Input
                          name="codigo_ncm"
                          placeholder="Código NCM"
                          onClick={() =>
                            resetFormErrors(formDados, "codigo_ncm")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item label="Cest" name="cest">
                        <Input name="cest" placeholder="Cest" />
                      </Form.Item>
                    </Col>
                    {selects?.icms_situacao_tributaria !== "00" && (
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label={
                            <>
                              Código beneficio fiscal{" "}
                              <Tooltip title="Caso o item não possua código, preencher com SEM CBENEF">
                                <IconInfo />
                              </Tooltip>
                            </>
                          }
                          name="codigo_beneficio_fiscal"
                          rules={[
                            {
                              required: true,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <Input
                            name="codigo_beneficio_fiscal"
                            placeholder="Código benefício fiscal"
                            onClick={() =>
                              resetFormErrors(
                                formDados,
                                "codigo_beneficio_fiscal"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="GTIN/EAN"
                        name="codigo_barras_comercial"
                      >
                        <Input
                          name="codigo_barras_comercial"
                          placeholder="GTIN/EAN"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="GTIN/EAN Tributário"
                        name="codigo_barras_tributavel"
                      >
                        <Input
                          name="codigo_barras_tributavel"
                          placeholder="GTIN/EAN Tributário"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Código de barras interno"
                        name="codigo_barras_proprio_comercial"
                      >
                        <Input
                          name="codigo_barras_proprio_comercial"
                          placeholder="Código de barras interno"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Código de barras tributário"
                        name="codigo_barras_proprio_tributavel"
                      >
                        <Input
                          name="codigo_barras_proprio_tributavel"
                          placeholder="Código de barras tributário"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item label="Peso liquido" name="peso_liquido">
                        <InputCurrency
                          type="float"
                          name="peso_liquido"
                          placeholder="0.00"
                          defaultValue={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            {" "}
                            Informações adicionais{" "}
                            <Tooltip title="Informações adicionais relacionadas ao item">
                              <IconInfo />
                            </Tooltip>
                          </span>
                        }
                        name="informacoes_adicionais"
                      >
                        <TextAreaInput
                          rows={2}
                          maxLength={100}
                          name="informacoes_adicionais"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tab="ICMS" key="2" forceRender={true}>
                <Form
                  layout="vertical"
                  form={formICMSTax}
                  validateTrigger="onSubmit"
                >
                  <Row gutter={[4, 4]}>
                    <Col sm={18} xs={24}>
                      <Form.Item
                        label={
                          <>
                            Situação tributária do ICMS{" "}
                            {selects.icms_situacao_tributaria !== "90" &&
                            selects.icms_situacao_tributaria !== "900" ? (
                              <span style={{ color: "var(--orange-600)" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        }
                        name="icms_situacao_tributaria"
                        rules={[
                          {
                            validator: () => {
                              return selects.icms_situacao_tributaria !== ""
                                ? Promise.resolve()
                                : Promise.reject("Campo obrigatório");
                            },
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione Aqui"
                          defaultValue={selects.icms_situacao_tributaria}
                          onChange={(value) => (
                            setSelects((oldValues) => ({
                              ...oldValues,
                              icms_situacao_tributaria: value.toString(),
                            })),
                            setCalcForm(value.toString())
                          )}
                          onClick={() =>
                            resetFormErrors(
                              formICMSTax,
                              "icms_situacao_tributaria"
                            )
                          }
                        >
                          {situationTrib.map((option) => (
                            <Select.Option
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item
                        label="Valor total tributos item"
                        name="valor_total_tributos"
                        rules={[
                          {
                            required: true,
                            message: "Campo obrigatório",
                          },
                        ]}
                      >
                        <InputCurrency
                          type="currency"
                          name="valor_total_tributos"
                          defaultValue={0}
                          onClick={() =>
                            resetFormErrors(formICMSTax, "valor_total_tributos")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <Form
                  layout="vertical"
                  form={formICMSGeral}
                  validateTrigger="onSubmit"
                >
                  <Row gutter={[4, 4]}>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        label={
                          <>
                            Origem{" "}
                            <span style={{ color: "var(--orange-600)" }}>
                              *
                            </span>
                          </>
                        }
                        name="icms_origem"
                        rules={[
                          {
                            validator: () => {
                              return selects.icms_origem !== ""
                                ? Promise.resolve()
                                : Promise.reject("Campo obrigatório");
                            },
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione Aqui"
                          defaultValue={selects.icms_origem}
                          onChange={(value) =>
                            setSelects((oldValues) => ({
                              ...oldValues,
                              icms_origem: value.toString(),
                            }))
                          }
                          onClick={() =>
                            resetFormErrors(formICMSGeral, "icms_origem")
                          }
                        >
                          {originICMS.map((option) => (
                            <Select.Option
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {(selects.icms_situacao_tributaria === "00" ||
                      selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "20" ||
                      selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={9} xs={24}>
                        <Form.Item
                          label="Modalidade BC ICMS"
                          name="icms_modalidade_base_calculo"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  selects.icms_situacao_tributaria !== "90" &&
                                  selects.icms_situacao_tributaria !==
                                    "90_partilha" &&
                                  selects.icms_situacao_tributaria !== "900"
                                ) {
                                  return selects.icms_modalidade_base_calculo !==
                                    ""
                                    ? Promise.resolve()
                                    : Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione Aqui"
                            defaultValue={selects?.icms_modalidade_base_calculo}
                            onChange={(value) => {
                              setSelects((oldValues) => ({
                                ...oldValues,
                                icms_modalidade_base_calculo: value.toString(),
                              }));
                              resetFormErrors(
                                formICMSGeral,
                                "icms_modalidade_base_calculo"
                              );
                            }}
                          >
                            {ICMSBaseCalc.map((option) => (
                              <Select.Option
                                key={option.label}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "00" ||
                      selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "20" ||
                      selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label={
                            <>
                              ICMS base de cálculo
                              {selects.icms_situacao_tributaria !== "90" &&
                              selects.icms_situacao_tributaria !==
                                "90_partilha" &&
                              selects.icms_situacao_tributaria !== "900" ? (
                                <span style={{ color: "var(--orange-600)" }}>
                                  *
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          }
                          name="icms_base_calculo"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  !inputItems.icms_base_calculo
                                ) {
                                  return Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? inputItems?.icms_base_calculo
                                : inputItems?.icms_base_calculo
                            }
                            getValue={(value) => {
                              icmsCalc("icms_base_calculo", +value);
                              resetFormErrors(
                                formICMSGeral,
                                "icms_base_calculo"
                              );
                              resetFormErrors(formICMSGeral, "icms_valor");
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "00" ||
                      selects.icms_situacao_tributaria === "02" ||
                      selects.icms_situacao_tributaria === "15" ||
                      selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "53" ||
                      selects.icms_situacao_tributaria === "20" ||
                      selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="ICMS aliquota"
                          name="icms_aliquota"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "90" ||
                                selects.icms_situacao_tributaria ===
                                  "90_partilha" ||
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  (value < 0.01 || value > 100)
                                ) {
                                  return Promise.reject(
                                    "Insira um valor entre 0.01 e 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            name="icms_aliquota"
                            suffix="%"
                            type="number"
                            min={0}
                            max={100}
                            step={0.01}
                            onChange={(event) => {
                              icmsCalc("icms_aliquota", +event.target.value);
                              resetFormErrors(formICMSGeral, "icms_aliquota");
                              resetFormErrors(formICMSGeral, "icms_valor");
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "00" ||
                      selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "20" ||
                      selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label={
                            <>
                              Valor ICMS
                              {selects.icms_situacao_tributaria !== "90" &&
                              selects.icms_situacao_tributaria !==
                                "90_partilha" &&
                              selects.icms_situacao_tributaria !== "900" ? (
                                <span style={{ color: "var(--orange-600)" }}>
                                  *
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          }
                          name="icms_valor"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  inputItems.icms_valor === 0
                                ) {
                                  return Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? inputItems?.icms_valor
                                : inputItems?.icms_valor
                            }
                            getValue={(value) => {
                              changeValue("icms_valor", +value);
                              resetFormErrors(formICMSGeral, "icms_valor");
                            }}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "02" ||
                      selects.icms_situacao_tributaria === "15" ||
                      selects.icms_situacao_tributaria === "53") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Base ICMS Mono"
                          name="icms_base_calculo_mono"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_base_calculo_mono"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_base_calculo_mono"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "02" ||
                      selects.icms_situacao_tributaria === "15" ||
                      selects.icms_situacao_tributaria === "53") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor ICMS Mono"
                          name="icms_valor_mono"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_mono"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(formICMSGeral, "icms_valor_mono")
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "15" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Aliq. Retenção"
                          name="icms_aliquota_retencao"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                            {
                              validator: (_, value) => {
                                if (value < 0.01 || value > 100) {
                                  return Promise.reject(
                                    "Insira um valor entre 0.01 e 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            name="icms_aliquota_retencao"
                            suffix="%"
                            type="number"
                            min={0}
                            max={100}
                            onChange={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_aliquota_retencao"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "15" && (
                      <Col sm={6} xs={24}>
                        <Form.Item
                          label="Base ICMS Mono Retenção"
                          name="icms_base_calculo_mono_retencao"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_base_calculo_mono_retencao"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_base_calculo_mono_retencao"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "15" && (
                      <Col sm={6} xs={24}>
                        <Form.Item
                          label="Valor ICMS Mono Retenção"
                          name="icms_valor_mono_retencao"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_mono_retencao"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_mono_retencao"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "15" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="% Redução"
                          name="icms_percentual_reducao"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <Input
                            name="icms_percentual_reducao"
                            placeholder="% Redução"
                            suffix="%"
                            type="number"
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_percentual_reducao"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {selects.icms_situacao_tributaria === "15" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Motivo redução"
                          name="icms_motivo_reducao"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <Select
                            placeholder="Selecione Aqui"
                            defaultValue={selects?.icms_motivo_reducao || ""}
                            onChange={(value) =>
                              setSelects((oldValues) => ({
                                ...oldValues,
                                icms_motivo_reducao: value.toString(),
                              }))
                            }
                          >
                            <Select.Option key={1} value={1}>
                              1: Transporte coletivo de passageiros
                            </Select.Option>
                            <Select.Option key={9} value={9}>
                              9: Outros
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "20" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "40" ||
                      selects.icms_situacao_tributaria === "41" ||
                      selects.icms_situacao_tributaria === "41_st" ||
                      selects.icms_situacao_tributaria === "50" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor ICMS desonerado"
                          name="icms_valor_desonerado"
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_desonerado"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_desonerado"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "202" ||
                      selects.icms_situacao_tributaria === "203" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={6} xs={24}>
                        <Form.Item
                          label="Modalidade BC ICMS ST"
                          name="icms_modalidade_base_calculo_st"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  selects.icms_situacao_tributaria !== "90" &&
                                  selects.icms_situacao_tributaria !==
                                    "90_partilha" &&
                                  selects.icms_situacao_tributaria !== "900"
                                ) {
                                  return selects.icms_modalidade_base_calculo !==
                                    ""
                                    ? Promise.resolve()
                                    : Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione Aqui"
                            defaultValue={
                              selects?.icms_modalidade_base_calculo_st
                            }
                            onChange={(value) => {
                              setSelects((oldValues) => ({
                                ...oldValues,
                                icms_modalidade_base_calculo_st:
                                  value.toString(),
                              }));
                              resetFormErrors(
                                formICMSGeral,
                                "icms_modalidade_base_calculo_st"
                              );
                            }}
                          >
                            {modalityBcICMS.map((option) => (
                              <Select.Option
                                key={option.label}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "20" ||
                      selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Redução base ICMS ST"
                          name="icms_reducao_base_calculo"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "90" ||
                                selects.icms_situacao_tributaria ===
                                  "90_partilha" ||
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_reducao_base_calculo"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_reducao_base_calculo"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "202" ||
                      selects.icms_situacao_tributaria === "203" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Redução base ICMS ST"
                          name="icms_reducao_base_calculo_st"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "90" ||
                                selects.icms_situacao_tributaria ===
                                  "90_partilha" ||
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_reducao_base_calculo_st"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_reducao_base_calculo_st"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "202" ||
                      selects.icms_situacao_tributaria === "203" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label={
                            <>
                              ICMS base de cálculo ST{" "}
                              {selects.icms_situacao_tributaria !== "90" &&
                              selects.icms_situacao_tributaria !==
                                "90_partilha" &&
                              selects.icms_situacao_tributaria !== "900" ? (
                                <span style={{ color: "var(--orange-600)" }}>
                                  *
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          }
                          name="icms_base_calculo_st"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  !inputItems.icms_base_calculo_st
                                ) {
                                  return Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? inputItems?.icms_base_calculo_st
                                : inputItems?.icms_base_calculo_st
                            }
                            getValue={(value) => {
                              icmsCalcST("icms_base_calculo_st", +value);
                              resetFormErrors(
                                formICMSGeral,
                                "icms_base_calculo_st"
                              );
                              resetFormErrors(formICMSGeral, "icms_valor_st");
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "202" ||
                      selects.icms_situacao_tributaria === "203" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="ICMS aliquota ST"
                          name="icms_aliquota_st"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "90" ||
                                selects.icms_situacao_tributaria ===
                                  "90_partilha" ||
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  (value < 0.01 || value > 100)
                                ) {
                                  return Promise.reject(
                                    "Insira um valor entre 0.01 e 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            name="icms_aliquota_st"
                            suffix="%"
                            type="number"
                            min={0}
                            max={100}
                            step={0.01}
                            onChange={(event) => {
                              icmsCalcST(
                                "icms_aliquota_st",
                                +event.target.value
                              );
                              resetFormErrors(
                                formICMSGeral,
                                "icms_aliquota_st"
                              );
                              resetFormErrors(formICMSGeral, "icms_valor_st");
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "202" ||
                      selects.icms_situacao_tributaria === "203" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label={
                            <>
                              Valor ICMS ST
                              {selects.icms_situacao_tributaria !== "90" &&
                              selects.icms_situacao_tributaria !==
                                "90_partilha" &&
                              selects.icms_situacao_tributaria !== "900" ? (
                                <span style={{ color: "var(--orange-600)" }}>
                                  *
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          }
                          name="icms_valor_st"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  inputItems.icms_valor_st === 0
                                ) {
                                  return Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? inputItems?.icms_valor_st
                                : inputItems?.icms_valor_st
                            }
                            getValue={(value) => {
                              changeValue("icms_valor_st", +value);
                              resetFormErrors(formICMSGeral, "icms_valor_st");
                            }}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "101" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Aliq. credito simples"
                          name="icms_aliquota_credito_simples"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  selects.icms_situacao_tributaria !== "900" &&
                                  (value < 0.01 || value > 100)
                                ) {
                                  return Promise.reject(
                                    "Insira um valor entre 0.01 e 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            name="icms_aliquota_credito_simples"
                            suffix="%"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "101" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor credito simples"
                          name="icms_valor_credito_simples"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_credito_simples"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_credito_simples"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "60" ||
                      selects.icms_situacao_tributaria === "60_st" ||
                      selects.icms_situacao_tributaria === "500") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Base ICMS retido ST"
                          name="icms_base_calculo_retido_st"
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_base_calculo_retido_st"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_base_calculo_retido_st"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "60" ||
                      selects.icms_situacao_tributaria === "60_st" ||
                      selects.icms_situacao_tributaria === "500") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor ICMS retido ST"
                          name="icms_valor_retido_st"
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_retido_st"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_retido_st"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "60" ||
                      selects.icms_situacao_tributaria === "60_st" ||
                      selects.icms_situacao_tributaria === "500") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Aliq. ICMS final"
                          name="icms_aliquota_final"
                        >
                          <Input
                            name="icms_aliquota_final"
                            suffix="%"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {(selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "53" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="% Dif. ICMS"
                          name="icms_percentual_diferimento"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <Input
                            name="icms_percentual_diferimento"
                            placeholder="% Dif. ICMS"
                            suffix="%"
                            type="number"
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_percentual_diferimento"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "53" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor mono dif"
                          name="icms_valor_mono_diferido"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_mono_diferido"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_mono_diferido"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor ICMS diferido"
                          name="icms_valor_diferido"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  selects.icms_situacao_tributaria === "51" &&
                                  inputItems.icms_valor_diferido === 0
                                ) {
                                  return Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? inputItems?.icms_valor_diferido
                                : inputItems?.icms_valor_diferido
                            }
                            getValue={() => {
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_diferido"
                              );
                            }}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "61" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Base ICMS mono retido"
                          name="icms_base_calculo_mono_retido"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_base_calculo_mono_retido"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_base_calculo_mono_retido"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "61" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Aliq. Retido"
                          name="icms_aliquota_retido"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                            {
                              validator: (_, value) => {
                                if (value < 0.01 || value > 100) {
                                  return Promise.reject(
                                    "Insira um valor entre 0.01 e 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            name="icms_aliquota_retido"
                            suffix="%"
                            type="number"
                            min={0}
                            max={100}
                            onChange={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_aliquota_retido"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "61" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor mono retido"
                          name="icms_valor_mono_retido"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_mono_retido"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_mono_retido"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {selects.icms_situacao_tributaria === "60" ||
                      (selects.icms_situacao_tributaria === "60_st" && (
                        <Col sm={4} xs={24}>
                          <Form.Item
                            label="Valor substituto"
                            name="icms_valor_substituto"
                          >
                            <InputCurrency
                              type="currency"
                              name="icms_valor_substituto"
                              defaultValue={0}
                              onClick={() =>
                                resetFormErrors(
                                  formICMSGeral,
                                  "icms_valor_substituto"
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    {selects.icms_situacao_tributaria === "53" && (
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor mono operação"
                          name="icms_valor_mono_operacao"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="icms_valor_mono_operacao"
                            defaultValue={0}
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_mono_operacao"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "10" ||
                      selects.icms_situacao_tributaria === "10_partilha" ||
                      selects.icms_situacao_tributaria === "30" ||
                      selects.icms_situacao_tributaria === "70" ||
                      selects.icms_situacao_tributaria === "90" ||
                      selects.icms_situacao_tributaria === "90_partilha" ||
                      selects.icms_situacao_tributaria === "201" ||
                      selects.icms_situacao_tributaria === "202" ||
                      selects.icms_situacao_tributaria === "203" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label="% margem valor adicionado ICMS ST"
                          name="icms_margem_valor_adicionado_st"
                          rules={[
                            {
                              required:
                                selects.icms_situacao_tributaria === "90" ||
                                selects.icms_situacao_tributaria ===
                                  "90_partilha" ||
                                selects.icms_situacao_tributaria === "900"
                                  ? false
                                  : true,
                              message: "Campo obrigatório",
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  !(
                                    selects.icms_situacao_tributaria === "90" ||
                                    selects.icms_situacao_tributaria ===
                                      "90_partilha" ||
                                    selects.icms_situacao_tributaria === "900"
                                  ) &&
                                  (value < 0.01 || value > 100)
                                ) {
                                  return Promise.reject(
                                    "Insira um valor entre 0.01 e 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            name="icms_margem_valor_adicionado_st"
                            placeholder="% margem valor adicionado ICMS ST"
                            suffix="%"
                            type="number"
                            onClick={() =>
                              resetFormErrors(
                                formICMSGeral,
                                "icms_margem_valor_adicionado_st"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(selects.icms_situacao_tributaria === "51" ||
                      selects.icms_situacao_tributaria === "900") && (
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label="Valor ICMS operação"
                          name="icms_valor_operacao"
                          rules={[
                            {
                              validator: () => {
                                if (
                                  selects.icms_situacao_tributaria === "51" &&
                                  inputItems.icms_valor_operacao === 0
                                ) {
                                  return Promise.reject("Campo obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? inputItems?.icms_valor_operacao
                                : inputItems?.icms_valor_operacao
                            }
                            getValue={() => {
                              resetFormErrors(
                                formICMSGeral,
                                "icms_valor_operacao"
                              );
                            }}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Form>
              </TabPane>
              <TabPane tab="PIS/Cofins" key="3" forceRender={true}>
                <Form
                  layout="vertical"
                  form={formPIS}
                  validateTrigger="onSubmit"
                >
                  <Row gutter={[4, 4]}>
                    <Col sm={24} xs={24}>
                      <Form.Item
                        label={
                          <>
                            Situação tributária do PIS{" "}
                            <span style={{ color: "var(--orange-600)" }}>
                              *
                            </span>
                          </>
                        }
                        name="pis_situacao_tributaria"
                        rules={[
                          {
                            validator: () => {
                              return selects.cofins_situacao_tributaria !== ""
                                ? Promise.resolve()
                                : Promise.reject("Campo obrigatório");
                            },
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione Aqui"
                          onChange={(value) =>
                            setSelects((oldValues) => ({
                              ...oldValues,
                              pis_situacao_tributaria: value.toString(),
                            }))
                          }
                          defaultValue={selects?.pis_situacao_tributaria}
                          onClick={() =>
                            resetFormErrors(formPIS, "pis_situacao_tributaria")
                          }
                        >
                          {pisCofinsTribSituation.map((option) => (
                            <Select.Option
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor PIS" name="pis_valor">
                        <Input type="number" />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Aliq. PIS"
                        name="pis_aliquota_porcentual"
                      >
                        <Input
                          name="pis_aliquota_porcentual"
                          suffix="%"
                          type="number"
                          min={0}
                          max={100}
                        />
                      </Form.Item>
                    </Col>

                    <>
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor Base PIS"
                          name="pis_base_calculo"
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </>
                  </Row>

                  <Row gutter={[4, 4]}>
                    <Col sm={24} xs={24}>
                      <Form.Item
                        label={
                          <>
                            Situação tributária do COFINS{" "}
                            <span style={{ color: "var(--orange-600)" }}>
                              *
                            </span>
                          </>
                        }
                        name="cofins_situacao_tributaria"
                        rules={[
                          {
                            validator: () => {
                              return selects.cofins_situacao_tributaria !== ""
                                ? Promise.resolve()
                                : Promise.reject("Campo obrigatório");
                            },
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione Aqui"
                          onChange={(value) =>
                            setSelects((oldValues) => ({
                              ...oldValues,
                              cofins_situacao_tributaria: value.toString(),
                            }))
                          }
                          defaultValue={selects?.cofins_situacao_tributaria}
                          onClick={() =>
                            resetFormErrors(
                              formPIS,
                              "cofins_situacao_tributaria"
                            )
                          }
                        >
                          {pisCofinsTribSituation.map((option) => (
                            <Select.Option
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor COFINS" name="cofins_valor">
                        <Input type="number" />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Aliq. COFINS"
                        name="cofins_aliquota_porcentual"
                      >
                        <Input
                          name="cofins_aliquota_porcentual"
                          suffix="%"
                          type="number"
                          min={0}
                          max={100}
                        />
                      </Form.Item>
                    </Col>

                    <>
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor Base COFINS"
                          name="cofins_base_calculo"
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tab="IPI" key="4" forceRender={true}>
                <Form
                  layout="vertical"
                  form={formIPI}
                  validateTrigger="onSubmit"
                >
                  <Row gutter={[4, 4]}>
                    <Col sm={24} xs={24}>
                      <Form.Item
                        label="Situação tributária do IPI"
                        name="ipi_situacao_tributaria"
                      >
                        <Select
                          placeholder="Selecione Aqui"
                          onChange={(value) =>
                            setSelects((oldValues) => ({
                              ...oldValues,
                              ipi_situacao_tributaria: value.toString(),
                            }))
                          }
                          defaultValue={selects?.ipi_situacao_tributaria || ""}
                          disabled={!selectItem?.ipi_situacao_tributaria}
                        >
                          {ipiSituacaoTrib.map((option) => (
                            <Select.Option
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {(selects.ipi_situacao_tributaria === "00" ||
                    selects.ipi_situacao_tributaria === "49" ||
                    selects.ipi_situacao_tributaria === "50" ||
                    selects.ipi_situacao_tributaria === "99") && (
                    <>
                      <Row gutter={[4, 4]}>
                        <Col sm={4} xs={24}>
                          <Form.Item
                            label="IPI Base de Calculo"
                            name="ipi_base_calculo"
                          >
                            <MonetaryInput
                              defaultValue={
                                loadingTotal
                                  ? inputItems?.ipi_base_calculo
                                  : inputItems?.ipi_base_calculo
                              }
                              getValue={(value) => {
                                changeValue("ipi_base_calculo", +value);
                                ipiCalc("ipi_base_calculo", +value);
                                resetFormErrors(formIPI, "ipi_base_calculo");
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={4} xs={24}>
                          <Form.Item label="IPI Aliq" name="ipi_aliquota">
                            <Input
                              name="ipi_aliquota"
                              suffix="%"
                              type="number"
                              min={0}
                              max={100}
                              onChange={(event) => {
                                ipiCalc("ipi_aliquota", +event.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={4} xs={24}>
                          <Form.Item label="Valor IPI" name="ipi_valor">
                            <MonetaryInput
                              defaultValue={
                                loadingTotal
                                  ? inputItems?.ipi_valor
                                  : inputItems?.ipi_valor
                              }
                              getValue={(value) => {
                                changeValue("ipi_valor", +value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={4} xs={24}>
                          <Form.Item
                            label="IPI quantidade total"
                            name="ipi_quantidade_total"
                          >
                            <Input name="ipi_quantidade_total" type="number" />
                          </Form.Item>
                        </Col>
                        <Col sm={4} xs={24}>
                          <Form.Item
                            label="IPI valor un trib"
                            name="ipi_valor_por_unidade_tributavel"
                          >
                            <Input
                              name="ipi_valor_por_unidade_tributavel"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={4} xs={24}>
                          <Form.Item
                            label="IPI CNPJ prod"
                            name="ipi_cnpj_produtor"
                          >
                            <InputMask
                              name="ipi_cnpj_produtor"
                              placeholder="99.999.999/99999"
                              mask="99.999.999/99999"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[4, 4]}>
                        <Col sm={12} xs={24}>
                          <Form.Item
                            label="Código selo controle IPI"
                            name="ipi_codigo_selo_controle"
                          >
                            <Select
                              placeholder="Selecione Aqui"
                              onChange={(value) =>
                                setSelects((oldValues) => ({
                                  ...oldValues,
                                  ipi_codigo_selo_controle: value.toString(),
                                }))
                              }
                              defaultValue={selects?.ipi_codigo_selo_controle}
                            >
                              {codigoSeloIpi.map((option) => (
                                <Select.Option
                                  key={option.label}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col sm={6} xs={24}>
                          <Form.Item
                            label="IPI Quantidade selo controle"
                            name="ipi_quantidade_selo_controle"
                          >
                            <Input
                              name="ipi_quantidade_selo_controle"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={6} xs={24}>
                          <Form.Item
                            label="IPI Código enquadramento legal"
                            name="ipi_codigo_enquadramento_legal"
                          >
                            <Input
                              name="ipi_codigo_enquadramento_legal"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              </TabPane>
            </Tabs>
          </>
        )}
      </>
    </Drawer>
  );
};

export default NFEDrawerItem;
