import React, { useEffect, useState } from "react";

import moment from "moment";

import api from "../../services/api";

import locale from "antd/es/date-picker/locale/pt_BR";
import { Checkbox, Col, Empty, Menu, Modal, Spin, message } from "antd";

import { useStoreData } from "../../hooks/useStoreData";

import { exportCSVFile } from "../../services/exportCSVFile";
import { currencyFormater } from "../../services/currencyFormater";
import { Handler } from "../../models/Handler";

import PageContainer from "../../containers/PageContainer";
import DatePickerAnt from "../../components/DatePicker";
import DisabledFlag from "../../components/DisabledFlag";
import Centralizer from "../../containers/Centralizer";

import {
  Container,
  Header,
  Row,
  Select,
  Option,
  EmptyContent,
  Table,
  Content,
  FilterMenu,
  FilterIcon,
  CheckboxMenu,
  Dropdown,
  DownloadButton,
  DownloadIcon,
} from "./styles";
import { currencyFormaterPYGBRL } from "../../services/currencyFormaterPYGBRL";

const Movements = () => {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState<number | null | undefined>();
  const [menuVisible, setMenuVisible] = useState(false);
  const [movimentations, setMovimentations] = useState<Handler[]>([]);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [selectedDate, setSelectedDate] = useState({
    data_inicial: moment(),
    data_final: moment(),
  });
  const { isStoreActive, stores, loadingStore, storeCountryPY } =
    useStoreData(store);

  const [filterChecked, setFilterChecked] = useState(false);
  const [filterUnchecked, setFilterUnchecked] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setShouldSearch(true);
  }, [selectedDate]);

  const formattedDates = {
    data_inicial: moment(selectedDate.data_inicial).format("DD/MM/YYYY"),
    data_final: moment(selectedDate.data_final)
      .add(1, "days")
      .format("DD/MM/YYYY"),
  };

  useEffect(() => {
    const loadCashHistory = async () => {
      setLoading(true);
      try {
        setShouldSearch(true);
        const { data: data } = await api.get(
          `/cash_handler/dates/${store}?data_inicial=${formattedDates.data_inicial}&data_final=${formattedDates.data_final}`
        );

        setMovimentations(data.data);
        setShouldSearch(false);
      } catch (error) {
        //@ts-ignore
        const errorMsg = error?.response?.data?.error?.message;

        message.error({
          message: errorMsg || "Erro ao carregar as movimentações",
          duration: 5,
        });
      } finally {
        setShouldSearch(false);
        setLoading(false);
      }
    };
    if (
      store &&
      shouldSearch &&
      formattedDates.data_inicial &&
      formattedDates.data_final
    ) {
      loadCashHistory();
    }
  }, [
    store,
    currentPage,
    shouldSearch,
    formattedDates.data_inicial,
    formattedDates.data_final,
  ]);

  const downloadCSV = () => {
    const CSVHeaders = {
      tipo: "Tipo",
      valor: "Valor",
      razao: "Motivo",
      verificada: "Verificada",
      criadoEm: "Data",
      criadoAs: "Hora",
    };
    const getFormatedData = () => moment(new Date()).format("DD-MM-YYYY");

    const getFormatedHandlers = () => {
      const payload = movimentations?.map((handler) => {
        const [date, time] = handler.created_at.split(" ");
        return {
          tipo: movimentations[handler.type],
          valor: handler.amount,
          razao: handler.reason.replace(/[^A-Za-z0-9]+/g, " "),
          verificada: handler.verified ? "Sim" : "Nao",
          criadoEm: date,
          criadoAs: time,
        };
      });
      return payload;
    };
    exportCSVFile(
      CSVHeaders,
      getFormatedHandlers(),
      `Movimentacoes_${getFormatedData()}`
    );
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckboxMenu onChange={(e) => setFilterChecked(e.target.checked)}>
          Verificado
        </CheckboxMenu>
        <CheckboxMenu onChange={(e) => setFilterUnchecked(e.target.checked)}>
          Não verificado
        </CheckboxMenu>
      </Menu.Item>
    </Menu>
  );

  const handleCheckBox = async (value) => {
    Modal.confirm({
      title: "Atualização movimentação",
      content: "Deseja continuar e atualizar esta movimentação?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        try {
          await api.put(`/cash_handler/${value?.id}`, {
            verified: !value?.verified,
          });
          setShouldSearch(true);
        } catch (error) {
          message.error("Erro ao marcar a movimentação");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const filteredMovimentations = movimentations.filter((entity) => {
    if (filterChecked && filterUnchecked) {
      return true;
    } else if (filterChecked) {
      return entity.verified;
    } else if (filterUnchecked) {
      return !entity.verified;
    } else {
      return true;
    }
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"] as any,
      width: "5%",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Horário",
      dataIndex: "created_at",
      responsive: ["xl"] as any,
      width: "20%",
      key: "created_at",
      render: (text) => (
        <span>
          {`${moment(text, "DD-MM-YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}`}{" "}
        </span>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "type",
      width: "20%",
      key: "type",
      render: (text) => <span>{text === 1 ? "Saída" : "Entrada"} </span>,
    },
    {
      title: "Valor",
      dataIndex: "amount",
      width: "20%",
      key: "amount",
      textWrap: "word-break",
      render: (text, record) => {
        const exchangeRate = record?.pyg_exchange_rate || 1;
        const amount = storeCountryPY ? +text / exchangeRate : +text;
        const currencyCode = storeCountryPY ? "PYG" : "BRL";
        const currencySymbol = storeCountryPY ? "Gs." : "R$";
        return (
          <span>
            {`${currencySymbol} ${currencyFormaterPYGBRL(
              amount,
              currencyCode
            )}`}
          </span>
        );
      },
    },
    {
      title: "Verificada",
      dataIndex: "verified",
      width: "20%",
      key: "verified",
      render: (text, record) => (
        <Checkbox
          checked={text}
          onClick={() => handleCheckBox(record)}
          value={text}
          disabled={isStoreActive}
        />
      ),
    },
    {
      title: "Razão",
      dataIndex: "reason",
      key: "reason",
      width: "20%",
      responsive: ["md"] as any,
      textWrap: "word-break",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <PageContainer route="Movimentações">
      <Container>
        <DisabledFlag isStoreActive={isStoreActive} />

        <Header>
          <Row gutter={8}>
            {store && selectedDate.data_inicial && selectedDate.data_final ? (
              <Col xl={1} sm={2} xs={12}>
                <Dropdown
                  overlay={menu}
                  onVisibleChange={(state) => setMenuVisible(state)}
                  visible={menuVisible}
                  trigger={["click"]}
                >
                  <FilterMenu>
                    <FilterIcon />
                  </FilterMenu>
                </Dropdown>
              </Col>
            ) : (
              <></>
            )}
            {store && !isStoreActive ? (
              <Col xl={3} sm={4} xs={12}>
                <DownloadButton
                  key="submit"
                  type="primary"
                  disabled={movimentations?.length ? false : true}
                  onClick={() => downloadCSV()}
                >
                  <span className="button-title">Download CSV</span>{" "}
                  <DownloadIcon />
                </DownloadButton>
              </Col>
            ) : (
              <></>
            )}

            <Col xl={6} sm={8} xs={24}>
              <Select
                style={{
                  textTransform: "capitalize",
                  marginBottom: 8,
                  marginTop: 8,
                }}
                placeholder="Selecione uma loja"
                onChange={(id) => {
                  setStore(+id);
                  setShouldSearch(true);
                }}
                defaultValue={store ? store : undefined}
                loading={loadingStore}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {stores.map((store) => (
                  <Option
                    key={store.key}
                    value={store.key}
                    style={{ textTransform: "capitalize" }}
                  >
                    {store.value}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xl={6} sm={10} xs={24}>
              <DatePickerAnt
                date={selectedDate}
                setDate={setSelectedDate}
                locale={locale}
                bordered={true}
              />
            </Col>
          </Row>
        </Header>

        {loading ? (
          <Centralizer>
            <Spin />
          </Centralizer>
        ) : (
          <>
            {store ? (
              movimentations?.length !== 0 ? (
                <Content>
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={filteredMovimentations.map((entity) => ({
                      ...entity,
                      key: entity.id,
                    }))}
                    pagination={{
                      current: currentPage,
                      total: filteredMovimentations.length,
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                      onChange: (page) => setCurrentPage(page),
                    }}
                  />
                </Content>
              ) : (
                <EmptyContent>
                  <Empty description="Não há movimentações nesse dia" />
                </EmptyContent>
              )
            ) : (
              <EmptyContent>
                <Empty description="Para visualizar as movimentações, selecione uma loja" />
              </EmptyContent>
            )}
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default Movements;
