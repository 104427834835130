import React, { useEffect, useState } from "react";
import { notification, Tooltip } from "antd";

import PageContainer from "../../../containers/PageContainer";
import ModalSummaryOrdersStatus from "../ModalSummaryOrdesStatus";
import moment, { Moment } from "moment";

import { Page } from "../../../models/Page";
import { Order as OrderModel } from "../../../models/Order/Order";
import OrdersList from "../OrdersList";
import {
  Button,
  Container,
  Header,
  CheckboxContainer,
  Checkbox,
  Content,
  BoxChangeStatus,
  Select,
  RangePicker,
} from "./styles";
import Pagination from "../../../components/Pagination";

import api from "../../../services/api";
import Centralizer from "../../../containers/Centralizer";
import Spinner from "../../../components/Spinner";
import { CategoryModel } from "../../../models/CategoryModel";
import { OrderItem } from "../../../models/Order/OrderItem";
import locale from "antd/es/date-picker/locale/pt_BR";
import { getTokenInfo } from "../../../services/auth";
import apiAuth from "../../../services/apiAuth";

const OrdersChangeStatus: React.FC = () => {
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [loadingChangeStatus, setLoadingChangeStatus] =
    useState<boolean>(false);

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 100,
    totalElements: 0,
  });

  const [geladoChecked, setGeladoChecked] = useState(false);
  const [secoChecked, setSecoChecked] = useState(false);
  const [todosChecked, setTodosChecked] = useState(false);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [visibleModalResumo, setVisibleModalResumo] = useState<boolean>(false);

  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment[]>([
    moment(new Date()).subtract(7, "days"),
    moment(new Date()),
  ]);

  const [ordersToUpdate, setOrdersToUpdate] = useState<OrderModel[]>([]);
  const [brasilCompanyIds, setBrasilCompanyIds] = useState<number[]>([]);
  const [companyIdsReady, setCompanyIdsReady] = useState<boolean>(false);
  const [status, setStatus] = useState(0);

  const handleSelectedOrders = (newSelectedOrderIds: number[]) => {
    if (!selectedDate) {
      notification.info({
        message: "Selecione uma data",
        description:
          "Você precisa selecionar uma data antes de selecionar os pedidos.",
        duration: 3,
      });
      return;
    }
    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleDateRangeChange = (dates: any) => {
    setSelectedDate(dates);
    clearSelections();
  };

  useEffect(() => {
    const fetchCompanyIds = async () => {
      setLoadingStore(true);
      try {
        const user = await getTokenInfo();
        const { data } = await apiAuth.get(`/companyUser/${user?.id}/user`);
        const companies = data.content;

        const brasilCompanies = companies.filter(
          (company) => company.company.country?.toLowerCase() === "brasil"
        );

        const brasilCompanyIds = brasilCompanies.map(
          (company) => company.company.id
        );

        setBrasilCompanyIds(brasilCompanyIds);
        console.log(brasilCompanyIds);
        setCompanyIdsReady(true);
      } catch (error) {
        const _description =
          //@ts-ignore
          error.response?.data || "Erro ao buscar empresas do Paraguai";
        notification.error({
          message: "Erro ao buscar empresas",
          description: _description,
          duration: 5,
        });
      } finally {
        setLoadingStore(false);
      }
    };

    fetchCompanyIds();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const {
          data: { content },
        } = await api.get(`/product_categories/products/orders`);

        setCategories(content);
      } catch (error) {
        //@ts-ignore
        const _description = error.data;

        notification.error({
          message: "Erro ao buscar categorias",
          description: _description,
          duration: 5,
        });
      }
    };
    fetchCategories();
  }, []);

  const fetchAllStoresOrders = async () => {
    if (!companyIdsReady || brasilCompanyIds.length === 0) return;

    setLoadingStore(true);

    try {
      const storeIds = brasilCompanyIds.join(",");
      let URL = `/orders?page=${paginate.page}&size=${paginate.size}&status=${status}&stores_ids=${storeIds}
      `;

      if (selectedDate && selectedDate[0] && selectedDate[1]) {
        let initialDate = selectedDate[0].format("DD/MM/YYYY");
        let finalDate = selectedDate[1].format("DD/MM/YYYY");

        URL += `&data_inicial=${initialDate}&data_final=${finalDate}`;
      }

      const { data: pagination } = await api.get(URL);
      const { totalElements, content } = pagination;

      setPaginate((oldValues) => ({ ...oldValues, totalElements }));

      setOrdersToUpdate(content);
    } catch (error) {
      const _description =
        //@ts-ignore
        error.response?.data || "Erro ao buscar pedidos";
      notification.error({
        message: "Erro ao buscar pedidos",
        description: _description,
        duration: 5,
      });
    } finally {
      setLoadingStore(false);
    }
  };

  useEffect(() => {
    fetchAllStoresOrders();
    clearSelections();
  }, [
    paginate.page,
    paginate.size,
    selectedDate,
    loadingChangeStatus,
    companyIdsReady,
    brasilCompanyIds,
    status,
  ]);

  const handleCheckboxChange = (type: number) => {
    let newSelectedOrderIds: number[] = [];

    if (type === 0) {
      setGeladoChecked(!geladoChecked);
      setSecoChecked(false);
      setTodosChecked(false);
      newSelectedOrderIds = !geladoChecked
        ? ordersToUpdate
            .filter((order) => order.type_of_load === 0)
            .map((order) => order.id)
        : [];
    } else if (type === 1) {
      setGeladoChecked(false);
      setSecoChecked(!secoChecked);
      setTodosChecked(false);
      newSelectedOrderIds = !secoChecked
        ? ordersToUpdate
            .filter((order) => order.type_of_load === 1)
            .map((order) => order.id)
        : [];
    } else if (type === 3) {
      setGeladoChecked(false);
      setSecoChecked(false);
      setTodosChecked(!todosChecked);
      newSelectedOrderIds = !todosChecked
        ? ordersToUpdate.map((order) => order.id)
        : [];
    }

    handleSelectedOrders(newSelectedOrderIds);
  };

  const clearSelections = () => {
    setGeladoChecked(false);
    setSecoChecked(false);
    setTodosChecked(false);
    setSelectedOrderIds([]);
  };

  const orderStatus = [
    { id: 0, description: "Em Aberto" },
    { id: 1, description: "Confirmado" },
    { id: 2, description: "Em Transporte" },
    { id: 3, description: "Finalizado" },
    { id: 4, description: "Cancelado" },
  ];

  return (
    <PageContainer route="Gerenciamento de Pedidos">
      <Container>
        {loadingStore ? (
          <Centralizer>
            <Spinner />
          </Centralizer>
        ) : (
          <>
            <Header>
              <CheckboxContainer>
                <h2>Lista de Pedidos</h2>
                <Checkbox
                  type="checkbox"
                  checked={geladoChecked}
                  onChange={() => handleCheckboxChange(0)}
                  disabled={todosChecked || secoChecked || loadingStore}
                >
                  Gelado
                </Checkbox>
                <Checkbox
                  checked={secoChecked}
                  onChange={() => handleCheckboxChange(1)}
                  disabled={todosChecked || geladoChecked || loadingStore}
                >
                  Seco
                </Checkbox>
                <Checkbox
                  checked={todosChecked}
                  onChange={() => handleCheckboxChange(3)}
                  disabled={secoChecked || geladoChecked || loadingStore}
                >
                  Todos
                </Checkbox>
              </CheckboxContainer>
              <BoxChangeStatus>
                <Select defaultValue={status} onChange={(id) => setStatus(+id)}>
                  {orderStatus.map((status) => (
                    <Select.Option
                      key={status.id}
                      value={status.id}
                      style={{ textTransform: "uppercase" }}
                    >
                      {status.description}
                    </Select.Option>
                  ))}
                </Select>
                <RangePicker
                  placeholder={["Data inicial", "Data final"]}
                  locale={locale}
                  format="DD/MM/YYYY"
                  value={[selectedDate[0], selectedDate[1]]}
                  onChange={handleDateRangeChange}
                />
                <>
                  <Button
                    disabled={
                      selectedOrderIds.length === 0 ||
                      loadingStore ||
                      !selectedDate
                    }
                    onClick={() => setVisibleModalResumo(true)}
                    loading={loadingStore}
                  >
                    Atualizar status
                  </Button>
                </>
              </BoxChangeStatus>
            </Header>
            <Content>
              {loadingStore ? (
                <Centralizer>
                  <Spinner />
                </Centralizer>
              ) : (
                <>
                  <OrdersList
                    orders={ordersToUpdate}
                    setShouldSearch={() => {}}
                    categories={categories}
                    setVisibleEdit={() => {}}
                    setOrderToEdit={() => {}}
                    isStoreActive={true}
                    showCheckboxes={true}
                    selectedOrderIds={selectedOrderIds}
                    onSelectedChange={handleSelectedOrders}
                  />
                  <Pagination
                    disabled={loadingStore}
                    setStateSearch={() => {}}
                    setPaginate={setPaginate}
                    defaultPageSize={100}
                    showSizeChanger={false}
                    current={paginate.page}
                    totalElements={paginate.totalElements}
                  />
                </>
              )}
            </Content>
          </>
        )}
        <ModalSummaryOrdersStatus
          visible={visibleModalResumo}
          status={status}
          setVisible={setVisibleModalResumo}
          selectedOrderIds={selectedOrderIds}
          orders={ordersToUpdate}
          setShouldSearch={setLoadingChangeStatus}
        />
      </Container>
    </PageContainer>
  );
};

export default OrdersChangeStatus;
