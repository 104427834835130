import styled from "styled-components";

import {
  Row as RowAnt,
  Checkbox as CheckboxAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
  DatePicker,
} from "antd";

const { RangePicker: RangePickerAnt } = DatePicker;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 15%;
  min-height: 3rem;

  h2 {
    font-size: 1.2rem;
    color: var(--gray-45);
    font-weight: 500;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 800px) {
    min-height: 6rem;
    h2 {
      display: none;
    }
  }
  @media only screen and (max-width: 575px) {
    min-height: 12rem;
  }
`;

export const Checkbox = styled(CheckboxAnt)``;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  background: var(--orange-gradient);
  color: white;
  border-radius: 0.31rem;
  border-color: var(--orange-gradient);

  :hover,
  :focus {
    filter: contrast(0.8);
    background: var(--orange-gradient);
    color: white;
    border-color: var(--orange-gradient);
  }
`;

export const CheckboxContainer = styled.div``;

export const ContentConsolidate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ContainerConsolidate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ContentCheckBox = styled.div`
  width: 60%;
`;

export const BoxChangeStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Select = styled(SelectAnt)`
  width: 15rem;
`;

export const RangePicker = styled(RangePickerAnt)`
  width: 15rem;
`;
