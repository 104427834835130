import React, { Dispatch, SetStateAction, useState } from "react";

import MonetaryInput from "../../../components/MonetaryInput2";

import api from "../../../services/api";

import OrderPaymentType from "../../../models/enums/OrderPaymentType.json";
import { Order as OrderModel } from "../../../models/Order/Order";

import { currencyFormater } from "../../../services/currencyFormater";

import { notification } from "antd";

import {
  Container,
  Row,
  Col,
  Select,
  Form,
  ButtonCancel,
  ButtonSave,
  ButtonAddFullValue,
  TotalPayment,
} from "./styles";

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  countryPY?: boolean;
}
const ModalPayment: React.FC<IProps> = ({
  order,
  setVisible,
  visible,
  setShouldSearch,
  countryPY,
}) => {
  const [payment, setPayment] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const valuePayment =
    order?.total_nf !== null && order?.total_nf !== undefined
      ? +(order?.total_nf || 0) - +(order?.payment_value || 0)
      : +(order?.total || 0) - +(order?.payment_value || 0);

  const resetFields = async () => {
    form.resetFields();
    setPayment(0);
    setPaymentType(1);
    setVisible(false);
  };

  const onFinish = async () => {
    form.validateFields();

    if (payment === 0) {
      return notification.warning({
        message: "Preencha os campos corretamente",
        description: "O campo Valor unitário deve ser preenchido",
        duration: 5,
      });
    }
    if (paymentType === null) {
      return notification.warning({
        message: "Preencha os campos corretamente",
        description: "Modo de pagamento deve ser selecionado",
        duration: 5,
      });
    }

    if (payment) {
      if (payment > valuePayment) {
        return notification.warning({
          message: "Valor inválido",
          description: "O valor inserido ultrapassa o valor restante.",
          duration: 5,
        });
      }
    }

    try {
      setLoading(true);
      await api.post("/orders_payments", {
        order_id: order?.id,
        payment_value: +payment,
        payment_type: paymentType,
      });

      notification.success({
        message: "Pagamento cadastrado",
        description: `O pagamento no valor de ${
          countryPY ? `US$` : `R$`
        } ${currencyFormater(+payment)} foi cadastrado com sucesso.`,
        duration: 5,
      });
      setShouldSearch(true);
      resetFields();
    } catch (error) {
      //@ts-ignore
      const _description = error.message;
      notification.error({
        message: "Oops! Não foi possível realizar o pagamento",
        description: error ? _description : "Falha ao realizar o pagamento.",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      title={`Confirmar Pagamento do Pedido - ${order?.vhsys}?`}
      visible={visible}
      centered
      destroyOnClose={true}
      width={550}
      closable={false}
      footer={[
        <ButtonCancel onClick={() => resetFields()}>Cancelar</ButtonCancel>,
        <ButtonSave
          loading={loading}
          disabled={payment > valuePayment}
          onClick={() => onFinish()}
        >
          Enviar
        </ButtonSave>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={4}>
          <Col sm={10} xs={24}>
            <Form.Item
              label="Método de pagamento"
              name="paymentType"
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório",
                },
              ]}
            >
              <Select
                placeholder="Selecione um modo"
                onChange={(value) => setPaymentType(+value)}
              >
                {OrderPaymentType.map((orderPaymentType) => (
                  <Select.Option
                    label={orderPaymentType.label}
                    value={orderPaymentType.value}
                    key={orderPaymentType.value}
                  >
                    {orderPaymentType.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col sm={10}>
            <Form.Item
              label="Valor unitário"
              name="payment"
              rules={[
                {
                  validator: () => {
                    if (!payment) {
                      return Promise.reject("Campo obrigatório");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <MonetaryInput
                getValue={(value) => setPayment(value)}
                defaultValue={+payment}
                currency={countryPY ? "USD" : "BRL"}
              />
            </Form.Item>
          </Col>
          <Col sm={1} style={{ minWidth: "3.3rem" }}>
            <Form.Item label=" ">
              <ButtonAddFullValue
                disabled={payment > valuePayment}
                onClick={() => setPayment(valuePayment)}
              >
                +
              </ButtonAddFullValue>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <TotalPayment>
          Valor restante a pagar: {countryPY ? `US$` : `R$`}{" "}
          <span style={{ color: "var(--orange-350)" }}>
            {+valuePayment > 0 ? currencyFormater(valuePayment) : 0}
          </span>
        </TotalPayment>
      </Row>
    </Container>
  );
};

export default ModalPayment;
