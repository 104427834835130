import React, { useState, useEffect } from "react";
import api from "../../../services/api";

import { Button, notification } from "antd";
import {
  Modal,
  LogsTotvsContainer,
  LogTotvsContent,
  LogTotvsTitle,
  LogTotvsDescription,
  Descriptions,
} from "./styles";

import { cleanSpecialChars } from "../../../services/cleanSpecialChars";
import { Order } from "../../../models/Order/Order";

interface ModalSummaryOrdersStatusProps {
  status: number;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  selectedOrderIds: number[];
  orders: Order[];
  setShouldSearch: (value: boolean) => void;
}

interface ITotvsIntegrationMessage {
  type: "success" | "error";
  id: number;
  message: string;
  error_message?: string;
}

interface IOrdersTotvsStatus {
  shouldShowTotvsSendingProgress: boolean;
  totalOrders: number;
  totalOrdersWithTotvsId: number;
  totalOrdersWithoutTotvsId: number;
  totalOrdersIntegrated: number;
  totalOrdersIntegratedError: number;
  totvsIntegrationMessage: ITotvsIntegrationMessage[];
}

const ModalSummaryOrdersStatus: React.FC<ModalSummaryOrdersStatusProps> = ({
  status,
  visible,
  setVisible,
  selectedOrderIds,
  orders,
  setShouldSearch,
}) => {
  const [loading, setLoading] = useState(false);
  const [ordersTotvsStatus, setOrdersTotvsStatus] =
    useState<IOrdersTotvsStatus>({
      shouldShowTotvsSendingProgress: false,
      totalOrders: 0,
      totalOrdersWithTotvsId: 0,
      totalOrdersWithoutTotvsId: 0,
      totalOrdersIntegrated: 0,
      totalOrdersIntegratedError: 0,
      totvsIntegrationMessage: [],
    });

  useEffect(() => {
    if (visible) {
      const selectedOrders = orders.filter((order) =>
        selectedOrderIds.some((selectedOrderId) => selectedOrderId === order.id)
      );
      setOrdersTotvsStatus({
        shouldShowTotvsSendingProgress: false,
        totalOrders: selectedOrderIds.length,
        totalOrdersWithTotvsId: selectedOrders.filter((order) => order.totvs_id)
          .length,
        totalOrdersWithoutTotvsId: selectedOrders.filter(
          (order) => !order.totvs_id
        ).length,
        totalOrdersIntegrated: 0,
        totalOrdersIntegratedError: 0,
        totvsIntegrationMessage: [],
      });
    }
  }, [visible, orders, selectedOrderIds]);

  function downloadTotvsLogs(_logs: ITotvsIntegrationMessage[]) {
    const content = _logs.reduce(
      (total, log) =>
        total + `[${log.id}]: ${log.message}\n${log.error_message}\n`,
      ""
    );

    const blob = new Blob([content], { type: "text/plain" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "totvsLogs.txt";

    link.click();

    URL.revokeObjectURL(link.href);
  }

  const onTotvsClick = async (
    action: "add" | "remove",
    order_ids: number[]
  ): Promise<{ success: boolean }> => {
    setLoading(true);

    setOrdersTotvsStatus((oldVaues) => ({
      ...oldVaues,
      shouldShowTotvsSendingProgress: true,
      totvsIntegrationMessage: [],
    }));

    let logs: ITotvsIntegrationMessage[] = [];

    for (const order_id of order_ids) {
      const order = orders.find((order) => order.id === order_id);
      if (
        (!order?.totvs_id && action === "add") ||
        (order?.totvs_id && action === "remove")
      ) {
        try {
          await api.patch(
            `/order/totvs/${order_id}/${action === "add" ? "send" : "remove"}`
          );

          logs = [
            ...logs,
            {
              type: "success",
              id: order?.vhsys || order_id,
              message: `Pedido ${
                action === "add" ? "enviado" : "estornado"
              } com sucesso`,
            },
          ];

          setOrdersTotvsStatus((oldValues) => ({
            ...oldValues,
            totalOrdersIntegrated: oldValues.totalOrdersIntegrated + 1,
            totvsIntegrationMessage: logs,
          }));
        } catch (error) {
          const error_message =
            //@ts-ignore
            error.response?.data?.error?.message ||
            //@ts-ignore
            error.response?.error?.message ||
            //@ts-ignore
            error.response?.data?.message ||
            //@ts-ignore
            error.response?.message ||
            //@ts-ignore
            error.message ||
            "Um erro interno aconteceu";

          logs = [
            ...logs,
            {
              type: "error",
              id: order?.vhsys || order_id,
              message: `Falha ao ${
                action === "add" ? "enviar" : "remover"
              } pedido ${action === "add" ? "à" : "da"} Totvs.`,
              error_message,
            },
          ];

          setOrdersTotvsStatus((oldValues) => ({
            ...oldValues,
            totalOrdersIntegratedError:
              oldValues.totalOrdersIntegratedError + 1,
            totvsIntegrationMessage: logs,
          }));
        }
      }
    }

    if (logs.some((message) => message.type === "error")) {
      downloadTotvsLogs(logs);
      setLoading(false);
    }

    return { success: logs.every((message) => message.type === "success") };
  };

  const handleUpdateOrdersClick = async (_status: number) => {
    const everyOrderHaveTotvsId = orders
      .filter(
        (order) =>
          !!selectedOrderIds.find(
            (selectedOrderId) => selectedOrderId === order.id
          )
      )
      .every((order) => !!order.totvs_id);
    if (_status === 0 && !everyOrderHaveTotvsId) {
      const totvsIngrationAllSuccess = await onTotvsClick(
        "add",
        selectedOrderIds
      );
      if (totvsIngrationAllSuccess.success) {
        setLoading(false);
        Modal.confirm({
          title: "Sincronização com a Totvs",
          content: `A sincronização com a totvs foi finalizada com sucesso.`,
          okText: "Ok",
          okType: "primary",
          cancelButtonProps: { hidden: true },
          onOk: () => {
            setShouldSearch(true);
            setVisible(false);
          },
        });
      } else {
        setLoading(false);
        Modal.confirm({
          title: "Sincronização com a Totvs",
          content: `A sincronização com a totvs foi finalizada e houveram falhas. Um arquivo de logs foi baixado para a conferencia. Pode-se encerrar a janela de status e sincronizar novamente após as correções ou caso queira, desmarque os pedidos que houveram falhas para seguir na atualização de status.`,
          okText: "Ok",
          okType: "primary",
          cancelButtonProps: { hidden: true },
        });
      }
    } else if (_status === 0) await handleUpdateOrders(1);
    else if (_status === 1) await handleUpdateOrders(2);
    else if (_status === 2) await handleUpdateOrders(3);
  };

  const handleUpdateOrders = async (status_to_update: number) => {
    try {
      setLoading(true);

      if (status_to_update === 4) {
        Modal.confirm({
          title: "Sincronização com a Totvs",
          content: `Você está prestes a estornar os pedidos da totvs e alterar os status dos pedidos para CANCELADO. Deseja prosseguir?`,
          okText: "Ok",
          okType: "primary",
          cancelText: "Cancelar",
          onOk: async () => {
            const totvsIngrationAllSuccess = await onTotvsClick(
              "remove",
              selectedOrderIds
            );
            if (!totvsIngrationAllSuccess) {
              notification.error({
                message: `Houveram pedidos com falha ao estornar da Totvs`,
                duration: 5,
              });
              setVisible(false);
              return;
            }

            await api.patch("/orders/update-status-orders", {
              orderIds: selectedOrderIds,
              status: status_to_update,
            });
            notification.success({
              message: "Pedidos atualizados com sucesso!",
              duration: 5,
            });
            setShouldSearch(true);
            setVisible(false);
          },
        });
      } else {
        await api.patch("/orders/update-status-orders", {
          orderIds: selectedOrderIds,
          status: status_to_update,
        });
        notification.success({
          message: "Pedidos atualizados com sucesso!",
          duration: 5,
        });
        setShouldSearch(true);
        setVisible(false);
      }
    } catch (error) {
      notification.error({
        message: "Erro ao confirmar os pedidos.",
        //@ts-ignore
        description: error.message,
        duration: 5,
      });
    } finally {
      setShouldSearch(false);

      setLoading(false);
    }
  };

  const handleTotalCategory = (category: string[], orderItems: any[]) => {
    const categoriesName = category.map((category) =>
      cleanSpecialChars(category).toLowerCase()
    );

    const resultTotalQuantity = orderItems.reduce(
      (total, orderItem) =>
        categoriesName.some(
          (category) =>
            category ===
            cleanSpecialChars(orderItem.products.category?.name.toLowerCase())
        )
          ? total + +orderItem.quantity
          : total + 0,
      0
    );

    const resultTotalWeight = orderItems.reduce(
      (total, orderItem) =>
        categoriesName.some(
          (category) =>
            category ===
            cleanSpecialChars(orderItem.products.category?.name.toLowerCase())
        )
          ? total + +orderItem.quantity * +(orderItem.products.weight || 0)
          : total + 0,
      0
    );

    return {
      totalQuantity: resultTotalQuantity || 0,
      totalWeight: resultTotalWeight || 0,
    };
  };

  const getTotalMetrics = (_selectedOrderIds: number[], _orders: Order[]) => {
    let totalAcai = 0;
    let totalSorvete = 0;
    let totalPotes = 0;
    let totalCongelados = 0;
    let totalOutros = 0;
    let totalWeight = 0;
    let totalStores = new Set();
    let totalOrders = selectedOrderIds.length;
    let totalGelada = 0;
    let totalSeca = 0;

    _selectedOrderIds.forEach((id) => {
      const order = _orders.find((order) => order.id === id);
      if (order) {
        const acai = handleTotalCategory(["acai"], order.orderItems);
        const sorvete = handleTotalCategory(["sorvetes"], order.orderItems);
        const potes = handleTotalCategory(
          ["potes p/ revenda"],
          order.orderItems
        );
        const congelados = handleTotalCategory(
          ["congelados"],
          order.orderItems
        );
        const outros = handleTotalCategory(
          order.orderItems
            .map((orderItem) => orderItem?.products?.category?.name as string)
            .filter(
              (categoryName) =>
                ![
                  "acai",
                  "sorvetes",
                  "potes p/ revenda",
                  "congelados",
                ].includes(categoryName)
            ),
          order.orderItems
        );

        totalAcai += acai.totalQuantity;
        totalSorvete += sorvete.totalQuantity;
        totalPotes += potes.totalQuantity;
        totalCongelados += congelados.totalQuantity;
        totalOutros += outros.totalQuantity;
        totalWeight +=
          acai.totalWeight +
          sorvete.totalWeight +
          potes.totalWeight +
          congelados.totalWeight +
          outros.totalWeight;

        // Soma o tipo de carga (Gelada ou Seca)
        if (order.type_of_load === 0) {
          totalGelada += 1;
        } else if (order.type_of_load === 1) {
          totalSeca += 1;
        }

        totalStores.add(order.store?.company_name);
      }
    });

    const totalStoresCount = totalStores.size;
    const avgBoxesPerStore =
      (totalAcai + totalSorvete + totalPotes + totalCongelados + totalOutros) /
      totalStoresCount;
    const avgWeightPerStore = totalWeight / totalStoresCount;

    return {
      totalAcai,
      totalSorvete,
      totalPotes,
      totalCongelados,
      totalOutros,
      totalWeight,
      totalGelada,
      totalSeca,
      totalOrders,
      totalStoresCount,
      avgBoxesPerStore,
      avgWeightPerStore,
    };
  };

  const getActionName = (_status: number): string => {
    const everyOrderHaveTotvsId = orders
      .filter(
        (order) =>
          !!selectedOrderIds.find(
            (selectedOrderId) => selectedOrderId === order.id
          )
      )
      .every((order) => !!order.totvs_id);

    if (_status === 0 && !everyOrderHaveTotvsId) return "Enviar p/ Totvs";
    if (_status === 0) return "Confirmar Pedidos";
    if (_status === 1) return "Mover p/ Transporte";
    return "Mover p/ Finalizados";
  };

  const handleClose = () => {
    setShouldSearch(true);
    setVisible(false);
  };

  return (
    <Modal
      title="Resumo dos pedidos"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        // <Button
        //   key="confirm"
        //   type="primary"
        //   onClick={async () => handleUpdateOrders(4)}
        //   loading={loading}
        //   style={{ backgroundColor: "red" }}
        // >
        //   Cancelar Pedidos
        // </Button>,
        <div>
          <Button key="cancel" onClick={handleClose} disabled={loading}>
            Cancelar
          </Button>

          <>
            {status === 3 ? (
              <></>
            ) : (
              <Button
                key="confirm"
                type="primary"
                onClick={() => handleUpdateOrdersClick(status)}
                loading={loading}
                disabled={ordersTotvsStatus.totvsIntegrationMessage.some(
                  (message) => message.type === "error"
                )}
              >
                {getActionName(status)}
              </Button>
            )}
          </>
        </div>,
      ]}
      width={600}
    >
      <Descriptions bordered column={1} size="small">
        <Descriptions.Item label="Total de Pedidos">
          {getTotalMetrics(selectedOrderIds, orders).totalOrders}
        </Descriptions.Item>
        <Descriptions.Item label="Pedidos com Carga Gelada">
          {getTotalMetrics(selectedOrderIds, orders).totalGelada}
        </Descriptions.Item>
        <Descriptions.Item label="Pedidos com Carga Seca">
          {getTotalMetrics(selectedOrderIds, orders).totalSeca}
        </Descriptions.Item>
        <Descriptions.Item label="Total de Caixas de Açaí">
          {getTotalMetrics(selectedOrderIds, orders).totalAcai}
        </Descriptions.Item>
        <Descriptions.Item label="Total de Caixas de Sorvete">
          {getTotalMetrics(selectedOrderIds, orders).totalSorvete}
        </Descriptions.Item>
        <Descriptions.Item label="Total de Potes">
          {getTotalMetrics(selectedOrderIds, orders).totalPotes}
        </Descriptions.Item>
        <Descriptions.Item label="Total de Congelados">
          {getTotalMetrics(selectedOrderIds, orders).totalCongelados}
        </Descriptions.Item>
        <Descriptions.Item label="Total de Outros">
          {getTotalMetrics(selectedOrderIds, orders).totalOutros}
        </Descriptions.Item>
        <Descriptions.Item label="Peso Total">
          {getTotalMetrics(selectedOrderIds, orders).totalWeight.toFixed(2)} KG
        </Descriptions.Item>
        <Descriptions.Item label="Total de Lojas">
          {getTotalMetrics(selectedOrderIds, orders).totalStoresCount}
        </Descriptions.Item>
        <Descriptions.Item label="Média de Caixas por Loja">
          {getTotalMetrics(selectedOrderIds, orders).avgBoxesPerStore.toFixed(
            2
          )}{" "}
          caixas/loja
        </Descriptions.Item>
        <Descriptions.Item label="Média de Peso por Loja">
          {getTotalMetrics(selectedOrderIds, orders).avgWeightPerStore.toFixed(
            2
          )}{" "}
          KG/loja
        </Descriptions.Item>
        {ordersTotvsStatus.shouldShowTotvsSendingProgress ? (
          <>
            {
              <>
                <Descriptions.Item label="Total de pedidos p/ sincronizar à totvs">
                  {ordersTotvsStatus.totalOrders}
                </Descriptions.Item>
                <Descriptions.Item label="Total de pedidos sincronizados à totvs">
                  {ordersTotvsStatus.totalOrdersIntegrated}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Total de pedidos com falha ao sincronizar à totvs"
                  style={{
                    color:
                      ordersTotvsStatus.totalOrdersIntegratedError > 0
                        ? "red"
                        : "",
                  }}
                >
                  {ordersTotvsStatus.totalOrdersIntegratedError}
                </Descriptions.Item>
              </>
            }
          </>
        ) : (
          <></>
        )}
      </Descriptions>
      {ordersTotvsStatus.shouldShowTotvsSendingProgress ? (
        <LogsTotvsContainer>
          {ordersTotvsStatus.totvsIntegrationMessage.map(
            (integrationMessage) => (
              <LogTotvsContent key={integrationMessage.id}>
                <LogTotvsTitle>
                  [
                  <strong
                    style={{
                      color:
                        integrationMessage.type === "success" ? "green" : "red",
                    }}
                  >
                    {integrationMessage.id}
                  </strong>
                  ]: {integrationMessage.message}
                </LogTotvsTitle>
                {integrationMessage.error_message ? (
                  <LogTotvsDescription>
                    {integrationMessage.error_message}
                  </LogTotvsDescription>
                ) : (
                  <></>
                )}
              </LogTotvsContent>
            )
          )}
        </LogsTotvsContainer>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ModalSummaryOrdersStatus;
